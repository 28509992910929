import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import {
  BALANCE_SHARING_LIST_REQUEST,
  listBalanceSharingSuccess,
  listBalanceSharingError,
} from './actions';

import { getCurrentOrganization } from '../organizations/selectors';

function* listBalanceSharing(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);

  const balanceSharing = yield call(
    api.balanceSharing,
    APIParams({
      ...payload.params,
      organizationId: currentOrganization.id,
    })
  );

  if (balanceSharing.ok) {
    const list = balanceSharing.data;

    yield put(listBalanceSharingSuccess({ list }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(listBalanceSharingError(message));
  }
}

export function* watchListBalanceSharing(api) {
  yield takeEvery(BALANCE_SHARING_LIST_REQUEST, listBalanceSharing, api);
}

export default function* rootSaga(api) {
  yield all([fork(watchListBalanceSharing, api)]);
}
