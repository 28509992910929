export const FUEL_MANAGEMENT_WALLETS_LIST_REQUEST =
  'FUEL_MANAGEMENT_WALLETS_LIST_REQUEST';
export const FUEL_MANAGEMENT_WALLETS_LIST_SUCCESS =
  'FUEL_MANAGEMENT_WALLETS_LIST_SUCCESS';
export const FUEL_MANAGEMENT_WALLETS_LIST_ERROR =
  'FUEL_MANAGEMENT_WALLETS_LIST_ERROR';
export const FUEL_MANAGEMENT_CREATE_WALLET = 'FUEL_MANAGEMENT_CREATE_WALLET';
export const FUEL_MANAGEMENT_CREATE_WALLET_SUCCESS =
  'FUEL_MANAGEMENT_CREATE_WALLET_SUCCESS';
export const FUEL_MANAGEMENT_CREATE_WALLET_ERROR =
  'FUEL_MANAGEMENT_CREATE_WALLET_ERROR';
export const FUEL_MANAGEMENT_UPDATE_WALLET = 'FUEL_MANAGEMENT_UPDATE_WALLET';
export const FUEL_MANAGEMENT_UPDATE_WALLET_SUCCESS =
  'FUEL_MANAGEMENT_UPDATE_WALLET_SUCCESS';
export const FUEL_MANAGEMENT_UPDATE_WALLET_ERROR =
  'FUEL_MANAGEMENT_UPDATE_WALLET_ERROR';
export const FUEL_MANAGEMENT_UPDATE_WALLET_LIMIT =
  'FUEL_MANAGEMENT_UPDATE_WALLET_LIMIT';
export const FUEL_MANAGEMENT_UPDATE_WALLET_LIMIT_SUCCESS =
  'FUEL_MANAGEMENT_UPDATE_WALLET_LIMIT_SUCCESS';
export const FUEL_MANAGEMENT_UPDATE_WALLET_LIMIT_ERROR =
  'FUEL_MANAGEMENT_UPDATE_WALLET_LIMIT_ERROR';
export const FUEL_MANAGEMENT_UPDATE_MULTIPLE_WALLETS =
  'FUEL_MANAGEMENT_UPDATE_MULTIPLE_WALLETS';
export const FUEL_MANAGEMENT_UPDATE_MULTIPLE_WALLETS_SUCCESS =
  'FUEL_MANAGEMENT_UPDATE_MULTIPLE_WALLETS_SUCCESS';
export const FUEL_MANAGEMENT_UPDATE_MULTIPLE_WALLETS_ERROR =
  'FUEL_MANAGEMENT_UPDATE_MULTIPLE_WALLETS_ERROR';
export const FUEL_MANAGEMENT_SHARE_BALANCE = 'FUEL_MANAGEMENT_SHARE_BALANCE';
export const FUEL_MANAGEMENT_SHARE_BALANCE_SUCCESS =
  'FUEL_MANAGEMENT_SHARE_BALANCE_SUCCESS';
export const FUEL_MANAGEMENT_SHARE_BALANCE_ERROR =
  'FUEL_MANAGEMENT_SHARE_BALANCE_ERROR';

export const walletsListRequest = params => ({
  type: FUEL_MANAGEMENT_WALLETS_LIST_REQUEST,
  payload: { params },
});

export const walletsListSuccess = payload => ({
  type: FUEL_MANAGEMENT_WALLETS_LIST_SUCCESS,
  payload,
});

export const walletsListError = message => ({
  type: FUEL_MANAGEMENT_WALLETS_LIST_ERROR,
  payload: { message },
});

export const createWallet = data => ({
  type: FUEL_MANAGEMENT_CREATE_WALLET,
  payload: { data },
});

export const createWalletSuccess = payload => ({
  type: FUEL_MANAGEMENT_CREATE_WALLET_SUCCESS,
  payload,
});

export const createWalletError = message => ({
  type: FUEL_MANAGEMENT_CREATE_WALLET_ERROR,
  payload: { message },
});

export const updateWallet = (id, data) => ({
  type: FUEL_MANAGEMENT_UPDATE_WALLET,
  payload: { id, data },
});

export const updateWalletSuccess = payload => ({
  type: FUEL_MANAGEMENT_UPDATE_WALLET_SUCCESS,
  payload,
});

export const updateWalletError = message => ({
  type: FUEL_MANAGEMENT_UPDATE_WALLET_ERROR,
  payload: { message },
});

export const updateWalletLimit = (data, params) => ({
  type: FUEL_MANAGEMENT_UPDATE_WALLET_LIMIT,
  payload: { data, params },
});

export const updateWalletLimitSuccess = () => ({
  type: FUEL_MANAGEMENT_UPDATE_WALLET_LIMIT_SUCCESS,
});

export const updateWalletLimitError = message => ({
  type: FUEL_MANAGEMENT_UPDATE_WALLET_LIMIT_ERROR,
  payload: { message },
});

export const updateMultipleWallets = data => ({
  type: FUEL_MANAGEMENT_UPDATE_MULTIPLE_WALLETS,
  payload: { data },
});

export const updateMultipleWalletsSuccess = payload => ({
  type: FUEL_MANAGEMENT_UPDATE_MULTIPLE_WALLETS_SUCCESS,
  payload,
});

export const updateMultipleWalletsError = message => ({
  type: FUEL_MANAGEMENT_UPDATE_MULTIPLE_WALLETS_ERROR,
  payload: { message },
});

export const shareBalance = data => ({
  type: FUEL_MANAGEMENT_SHARE_BALANCE,
  payload: { data },
});

export const shareBalanceSuccess = payload => ({
  type: FUEL_MANAGEMENT_SHARE_BALANCE_SUCCESS,
  payload,
});

export const shareBalanceError = message => ({
  type: FUEL_MANAGEMENT_SHARE_BALANCE_ERROR,
  payload: { message },
});
