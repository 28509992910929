export const ROUTE_STATIONS_PROCESS_REQUEST = 'ROUTE_STATIONS_PROCESS_REQUEST';
export const ROUTE_STATIONS_PROCESS_SUCCESS = 'ROUTE_STATIONS_PROCESS_SUCCESS';
export const ROUTE_STATIONS_PROCESS_ERROR = 'ROUTE_STATIONS_PROCESS_ERROR';

export const processRouteStations = data => ({
  type: ROUTE_STATIONS_PROCESS_REQUEST,
  payload: { data },
});

export const onProcessSuccess = data => ({
  type: ROUTE_STATIONS_PROCESS_SUCCESS,
  payload: { data },
});

export const onProcessError = message => ({
  type: ROUTE_STATIONS_PROCESS_ERROR,
  payload: { message },
});
