export const PURCHASE_LIST_REQUEST = 'PURCHASE_LIST_REQUEST';
export const PURCHASE_LIST_SUCCESS = 'PURCHASE_LIST_SUCCESS';
export const PURCHASE_LIST_ERROR = 'PURCHASE_LIST_ERROR';
export const PURCHASE_CREATE_REQUEST = 'PURCHASE_CREATE_REQUEST';
export const PURCHASE_CREATE_SUCCESS = 'PURCHASE_CREATE_SUCCESS';
export const PURCHASE_CREATE_ERROR = 'PURCHASE_CREATE_ERROR';
export const CLEAR_META_DATA = 'CLEAR_META_DATA';
export const PURCHASE_LIST_ALL_REQUEST = 'PURCHASE_LIST_ALL_REQUEST';
export const PURCHASE_LIST_ALL_SUCCESS = 'PURCHASE_LIST_ALL_SUCCESS';
export const PURCHASE_LIST_ALL_ERROR = 'PURCHASE_LIST_ALL_ERROR';

export const listPurchase = params => ({
  type: PURCHASE_LIST_REQUEST,
  payload: { params },
});

export const listPurchaseSuccess = payload => ({
  type: PURCHASE_LIST_SUCCESS,
  payload,
});

export const listPurchaseError = message => ({
  type: PURCHASE_LIST_ERROR,
  payload: { message },
});

export const listAllPurchase = params => ({
  type: PURCHASE_LIST_ALL_REQUEST,
  payload: { params },
});

export const listAllPurchaseSuccess = payload => ({
  type: PURCHASE_LIST_ALL_SUCCESS,
  payload,
});

export const listAllPurchaseError = message => ({
  type: PURCHASE_LIST_ALL_ERROR,
  payload: { message },
});

export const createPurchase = (data, params) => ({
  type: PURCHASE_CREATE_REQUEST,
  payload: { data, params },
});

export const createPurchaseSuccess = payload => ({
  type: PURCHASE_CREATE_SUCCESS,
  payload,
});

export const createPurchaseError = message => ({
  type: PURCHASE_CREATE_ERROR,
  payload: { message },
});

export const clearMetaData = () => ({
  type: CLEAR_META_DATA,
});
