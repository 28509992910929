export const ADMINISTRATORS_LIST_REQUEST = 'ADMINISTRATORS_LIST_REQUEST';
export const REGISTER_ADMINISTRATOR = 'REGISTER_ADMINISTRATOR';
export const REGISTER_ADMINISTRATOR_SUCCESS = 'REGISTER_ADMINISTRATOR_SUCCESS';
export const REGISTER_ADMINISTRATOR_ERROR = 'REGISTER_ADMINISTRATOR_ERROR';
export const ADMINISTRATORS_LIST_SUCCESS = 'ADMINISTRATORS_LIST_SUCCESS';
export const ADMINISTRATORS_LIST_ERROR = 'ADMINISTRATORS_LIST_ERROR';
export const DELETE_ADMINISTRATOR = 'DELETE_ADMINISTRATOR';
export const DELETE_ADMINISTRATOR_ERROR = 'DELETE_ADMINISTRATOR_ERROR';
export const EDIT_ADMINISTRATOR = 'EDIT_ADMINISTRATOR';
export const EDIT_ADMINISTRATOR_ERROR = 'EDIT_ADMINISTRATOR_ERROR';
export const EDIT_ADMINISTRATOR_BRANCH = 'EDIT_ADMINISTRATOR_BRANCH';
export const EDIT_ADMINISTRATOR_BRANCH_ERROR =
  'EDIT_ADMINISTRATOR_BRANCH_ERROR';

export const listAdministrators = params => ({
  type: ADMINISTRATORS_LIST_REQUEST,
  payload: { params },
});

export const listAdministratorsSuccess = payload => ({
  type: ADMINISTRATORS_LIST_SUCCESS,
  payload,
});

export const listAdministratorsError = message => ({
  type: ADMINISTRATORS_LIST_ERROR,
  payload: { message },
});

export const registerAdministrator = (body, params) => ({
  type: REGISTER_ADMINISTRATOR,
  payload: { body, params },
});

export const registerAdministratorSuccess = () => ({
  type: REGISTER_ADMINISTRATOR_SUCCESS,
});

export const registerAdministratorError = message => ({
  type: REGISTER_ADMINISTRATOR_ERROR,
  payload: { message },
});

export const deleteAdministrator = (id, params) => ({
  type: DELETE_ADMINISTRATOR,
  payload: { id, params },
});

export const deleteAdministratorError = message => ({
  type: DELETE_ADMINISTRATOR_ERROR,
  payload: { message },
});

export const editAdministrator = (id, body, params) => ({
  type: EDIT_ADMINISTRATOR,
  payload: { id, body, params },
});

export const editAdministratorError = message => ({
  type: EDIT_ADMINISTRATOR_ERROR,
  payload: { message },
});

export const editAdministratorBranch = (id, body, params) => ({
  type: EDIT_ADMINISTRATOR_BRANCH,
  payload: { id, body, params },
});

export const editAdministratorBranchError = message => ({
  type: EDIT_ADMINISTRATOR_BRANCH_ERROR,
  payload: { message },
});
