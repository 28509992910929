import produce from 'immer';

import {
  USER_PERMISSION_REQUEST,
  USER_PERMISSION_SUCCESS,
  USER_PERMISSION_ERROR,
} from './actions';

const INIT_STATE = {
  userPermissions: [],
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case USER_PERMISSION_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case USER_PERMISSION_SUCCESS: {
        draft.loading = false;
        draft.userPermissions = action.payload.data;
        draft.error = '';
        break;
      }

      case USER_PERMISSION_ERROR: {
        draft.loading = false;
        draft.userPermissions = [];
        draft.error = action.payload.message;
        break;
      }

      default:
        return state;
    }
  });
};
