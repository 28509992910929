import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import * as orgSettings from './actions';
import { getCurrentOrganization } from '../organizations/selectors';

function* listOrgSettings(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.list,
    APIParams({ ...payload.params, organizationId: currentOrganization.id })
  );

  if (response.ok) {
    const list = response.data;
    yield put(orgSettings.listOrgSettingsSuccess({ list }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(orgSettings.listOrgSettingsError(message));
  }
}

function* updateOrgSettings(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(api.update, payload, currentOrganization.id);

  if (response.ok) {
    yield put(orgSettings.updateOrgSettingsSuccess({}));
    yield put(orgSettings.listOrgSettingsRequest({}));
  } else {
    const message = 'Não foi possível atualizar as configurações';
    yield put(orgSettings.updateOrgSettingsError(message));
  }
}

export function* watchListOrgSettings(api) {
  yield takeEvery(orgSettings.ORG_SETTINGS_LIST_REQUEST, listOrgSettings, api);
}

export function* watchUpdateOrgSettings(api) {
  yield takeEvery(
    orgSettings.ORG_SETTINGS_UPDATE_REQUEST,
    updateOrgSettings,
    api
  );
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListOrgSettings, api),
    fork(watchUpdateOrgSettings, api),
  ]);
}
