export const BALANCE_SHARING_LIST_REQUEST = 'BALANCE_SHARING_LIST_REQUEST';
export const BALANCE_SHARING_LIST_SUCCESS = 'BALANCE_SHARING_LIST_SUCCESS';
export const BALANCE_SHARING_LIST_ERROR = 'BALANCE_SHARING_LIST_ERROR';

export const listBalanceSharing = params => ({
  type: BALANCE_SHARING_LIST_REQUEST,
  payload: { params },
});

export const listBalanceSharingSuccess = payload => ({
  type: BALANCE_SHARING_LIST_SUCCESS,
  payload,
});

export const listBalanceSharingError = message => ({
  type: BALANCE_SHARING_LIST_ERROR,
  payload: { message },
});
