export const FUEL_MANAGEMENT_USERS_LIST_REQUEST =
  'FUEL_MANAGEMENT_USERS_LIST_REQUEST';
export const FUEL_MANAGEMENT_USERS_LIST_SUCCESS =
  'FUEL_MANAGEMENT_USERS_LIST_SUCCESS';
export const FUEL_MANAGEMENT_USERS_LIST_ERROR =
  'FUEL_MANAGEMENT_USERS_LIST_ERROR';

export const listFuelManagementUsers = params => ({
  type: FUEL_MANAGEMENT_USERS_LIST_REQUEST,
  payload: { params },
});

export const listFuelManagementUsersSuccess = payload => ({
  type: FUEL_MANAGEMENT_USERS_LIST_SUCCESS,
  payload,
});

export const listFuelManagementUsersError = message => ({
  type: FUEL_MANAGEMENT_USERS_LIST_ERROR,
  payload: { message },
});
