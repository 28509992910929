import { all, fork, call, put, takeEvery } from 'redux-saga/effects';
import * as maps from './actions';

function* findPlaces({ payload: { google, request } }) {
  const sendEmptyList = () => put(maps.onFindPlacesSuccess([]));
  if (request.input) {
    const AutocompleteService = new google.maps.places.AutocompleteService();
    const { PlacesServiceStatus } = google.maps.places;
    const requestPredictions = () => {
      return new Promise(resolve => {
        AutocompleteService.getPlacePredictions(
          request,
          (predictions, status) => {
            resolve({ predictions, status });
          }
        );
      });
    };

    const response = yield call(requestPredictions);

    if (response.status === PlacesServiceStatus.OK) {
      const places = response.predictions;

      yield put(maps.onFindPlacesSuccess(places));
    } else if (
      response.status === PlacesServiceStatus.NOT_FOUND ||
      response.status === PlacesServiceStatus.ZERO_RESULTS
    ) {
      yield sendEmptyList();
    } else {
      const message = 'Não foi possível realizar a pesquisa';

      yield put(maps.onFindPlacesError(message));
    }
  } else {
    yield sendEmptyList();
  }
}

function* getPlace({ payload: { google, request } }) {
  const { PlacesServiceStatus } = google.maps.places;
  const Geocoder = new google.maps.Geocoder();
  const requestPlaceDetails = () => {
    return new Promise(resolve => {
      Geocoder.geocode(
        {
          placeId: request.id,
        },
        (results, status) => {
          resolve({ results, status });
        }
      );
    });
  };

  const response = yield call(requestPlaceDetails);

  if (response.status === PlacesServiceStatus.OK) {
    const place = response.results[0];

    yield put(
      maps.onGetPlaceSuccess({ ...place, suggestion_place_id: request.id })
    );
  } else if (
    response.status === PlacesServiceStatus.NOT_FOUND ||
    response.status === PlacesServiceStatus.ZERO_RESULTS
  ) {
    yield put(maps.onGetPlaceSuccess(null));
  } else {
    const message = 'Não foi possível realizar a pesquisa';

    yield put(maps.onGetPlaceError(message));
  }
}

export function* watchFindPlaces() {
  yield takeEvery(maps.SUGGESTION_REQUEST, findPlaces);
}

export function* watchGetPlace() {
  yield takeEvery(maps.PLACE_REQUEST, getPlace);
}

export default function* rootSaga() {
  yield all([fork(watchFindPlaces), fork(watchGetPlace)]);
}
