import { APIParams } from 'helpers/Utils';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import * as userPermissions from './actions';
import * as selectors from './selectors';

function* ListUsers(api, { payload }) {
  const response = yield call(
    api.getUsers,
    APIParams({
      ...payload.params,
    })
  );

  if (response.ok) {
    const usersAdmin = response.data.docs;

    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(
      userPermissions.getUsersSuccess({ list: usersAdmin, pages, items })
    );
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(userPermissions.getUsersError(message));
  }
}

function* listUserPermissions(api, { payload }) {
  const response = yield call(
    api.getUserPermission,
    APIParams({
      ...payload.params,
    })
  );

  if (response.ok) {
    const { data } = response;
    yield put(userPermissions.getUserPermissionsSuccess(data));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(userPermissions.getUserPermissionsError(message));
  }
}

function* createUserPermissions(api, { payload }) {
  const permissions = yield select(selectors.getPermissions);

  const response = yield call(api.createUserPermission, {
    _permission: payload.permissionId,
    _user: payload.userId,
    status: payload.status,
  });

  if (response.ok) {
    const updatedPermissions = permissions.map(permission => {
      if (permission._id === payload.permissionId) {
        return {
          ...permission,
          userPermission: {
            ...response.data,
          },
        };
      }
      return permission;
    });

    yield put(userPermissions.createUserPermissionsSuccess(updatedPermissions));
  } else {
    const message = 'Ocorreu um erro ao alterar permissão';
    yield put(userPermissions.createUserPermissionsError(message));
  }
}

function* updateUserPermissions(api, { payload }) {
  const permissions = yield select(selectors.getPermissions);

  const response = yield call(
    api.updateUserPermission,
    payload.id,
    payload.status
  );

  if (response.ok) {
    const updatedPermissions = permissions.map(permission => {
      if (permission._id === payload.permissionId) {
        return {
          ...permission,
          userPermission: {
            ...response.data,
          },
        };
      }
      return permission;
    });

    yield put(userPermissions.updateUserPermissionsSuccess(updatedPermissions));
  } else {
    const message = 'Ocorreu um erro ao alterar permissão';
    yield put(userPermissions.updateUserPermissionsError(message));
  }
}

function* watchListUsers(api) {
  yield takeEvery(userPermissions.ACTIONS.GET_USERS, ListUsers, api);
}

function* watchListUserPermissions(api) {
  yield takeEvery(
    userPermissions.ACTIONS.GET_USER_PERMISSIONS,
    listUserPermissions,
    api
  );
}

function* watchCreateUserPermissions(api) {
  yield takeEvery(
    userPermissions.ACTIONS.CREATE_USER_PERMISSION,
    createUserPermissions,
    api
  );
}

function* watchUpdatetUserPermissions(api) {
  yield takeEvery(
    userPermissions.ACTIONS.UPDATE_USER_PERMISSION,
    updateUserPermissions,
    api
  );
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListUserPermissions, api),
    fork(watchCreateUserPermissions, api),
    fork(watchUpdatetUserPermissions, api),
    fork(watchListUsers, api),
  ]);
}
