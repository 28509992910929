import produce from 'immer';

import {
  DRIVER_LIST_REQUEST,
  DRIVER_LIST_SUCCESS,
  DRIVER_LIST_ERROR,
  DRIVER_REGISTER_REQUEST,
  DRIVER_REGISTER_SUCCESS,
  DRIVER_REGISTER_ERROR,
  DRIVER_DELETE_REQUEST,
  DRIVER_DELETE_ERROR,
  DRIVER_EDIT_REQUEST,
  DRIVER_EDIT_ERROR,
  DRIVER_UNDELETE_REQUEST,
  DRIVER_UNDELETE_ERROR,
  DRIVER_TURN_RECIPIENT_REQUEST,
  DRIVER_TURN_RECIPIENT_ERROR,
  DRIVER_TURN_RECIPIENT_SUCCESS,
  DRIVER_TURN_RECIPIENT_RESET,
  DRIVER_PASSWORD_RECOVERY_REQUEST,
  DRIVER_PASSWORD_RECOVERY_ERROR,
  DRIVER_REGISTER_RESET,
  DRIVER_REGISTER_IMPORTED_REQUEST,
} from './actions';

const INIT_STATE = {
  list: [],
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  loadingImportDriver: false,
  error: '',
  success: false,
  turnRecipient: {
    loading: false,
    error: '',
    success: false,
  },
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case DRIVER_LIST_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case DRIVER_LIST_SUCCESS: {
        draft.loading = false;
        draft.list = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      }

      case DRIVER_LIST_ERROR: {
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      }

      case DRIVER_REGISTER_IMPORTED_REQUEST: {
        draft.loading = true;
        draft.loadingImportDriver = true;
        draft.error = '';
        break;
      }

      case DRIVER_REGISTER_REQUEST: {
        draft.loading = true;
        draft.loadingImportDriver = true;
        draft.error = '';
        break;
      }

      case DRIVER_REGISTER_SUCCESS: {
        draft.loading = false;
        draft.loadingImportDriver = false;
        draft.success = true;
        draft.error = '';
        break;
      }

      case DRIVER_REGISTER_ERROR: {
        draft.loading = false;
        draft.loadingImportDriver = false;
        draft.success = false;
        draft.error = action.payload.message;
        break;
      }

      case DRIVER_REGISTER_RESET: {
        draft.loading = false;
        draft.loadingImportDriver = false;
        draft.success = false;
        draft.error = '';
        break;
      }

      case DRIVER_DELETE_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case DRIVER_DELETE_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }

      case DRIVER_EDIT_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case DRIVER_EDIT_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }

      case DRIVER_UNDELETE_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case DRIVER_UNDELETE_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }

      case DRIVER_TURN_RECIPIENT_REQUEST: {
        draft.turnRecipient.loading = true;
        draft.turnRecipient.error = '';
        break;
      }

      case DRIVER_TURN_RECIPIENT_ERROR: {
        draft.turnRecipient.loading = false;
        draft.turnRecipient.error = action.payload.message;
        draft.turnRecipient.success = false;
        break;
      }

      case DRIVER_TURN_RECIPIENT_SUCCESS: {
        draft.turnRecipient.loading = false;
        draft.turnRecipient.error = '';
        draft.turnRecipient.success = true;
        break;
      }

      case DRIVER_TURN_RECIPIENT_RESET: {
        draft.turnRecipient.loading = false;
        draft.turnRecipient.error = '';
        draft.turnRecipient.success = false;
        break;
      }

      case DRIVER_PASSWORD_RECOVERY_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case DRIVER_PASSWORD_RECOVERY_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }

      default:
        return state;
    }
  });
};
