export const RECIPIENT_LIST_REQUEST = 'RECIPIENT_LIST_REQUEST';
export const RECIPIENT_LIST_SUCCESS = 'RECIPIENT_LIST_SUCCESS';
export const RECIPIENT_LIST_ERROR = 'RECIPIENT_LIST_ERROR';

export const RECIPIENT_REGISTER_REQUEST = 'RECIPIENT_REGISTER_REQUEST';
export const RECIPIENT_REGISTER_SUCCESS = 'RECIPIENT_REGISTER_SUCCESS';
export const RECIPIENT_REGISTER_ERROR = 'RECIPIENT_REGISTER_ERROR';

export const RECIPIENT_DELETE_REQUEST = 'RECIPIENT_DELETE_REQUEST';
export const RECIPIENT_DELETE_ERROR = 'RECIPIENT_DELETE_ERROR';

export const RECIPIENT_EDIT_REQUEST = 'RECIPIENT_EDIT_REQUEST';
export const RECIPIENT_EDIT_ERROR = 'RECIPIENT_EDIT_ERROR';

export const RECIPIENT_UNDELETE_REQUEST = 'RECIPIENT_UNDELETE_REQUEST';
export const RECIPIENT_UNDELETE_ERROR = 'RECIPIENT_UNDELETE_ERROR';

export const RECIPIENT_TURN_DRIVER_REQUEST = 'RECIPIENT_TURN_DRIVER_REQUEST';
export const RECIPIENT_TURN_DRIVER_ERROR = 'RECIPIENT_TURN_DRIVER_ERROR';
export const RECIPIENT_TURN_DRIVER_SUCCESS = 'RECIPIENT_TURN_DRIVER_SUCCESS';
export const RECIPIENT_TURN_DRIVER_RESET = 'RECIPIENT_TURN_DRIVER_RESET';

export const RECIPIENT_PASSWORD_RECOVERY_REQUEST =
  'RECIPIENT_PASSWORD_RECOVERY_REQUEST';
export const RECIPIENT_PASSWORD_RECOVERY_ERROR =
  'RECIPIENT_PASSWORD_RECOVERY_ERROR';

export const listRecipientRequest = params => ({
  type: RECIPIENT_LIST_REQUEST,
  payload: { params },
});

export const listRecipientSuccess = payload => ({
  type: RECIPIENT_LIST_SUCCESS,
  payload,
});

export const listRecipientError = message => ({
  type: RECIPIENT_LIST_ERROR,
  payload: { message },
});

export const registerRecipientRequest = (body, params) => ({
  type: RECIPIENT_REGISTER_REQUEST,
  payload: { body, params },
});

export const registerRecipientSuccess = () => ({
  type: RECIPIENT_REGISTER_SUCCESS,
});

export const registerRecipientError = message => ({
  type: RECIPIENT_REGISTER_ERROR,
  payload: { message },
});

export const deleteRecipientRequest = (id, params) => ({
  type: RECIPIENT_DELETE_REQUEST,
  payload: { id, params },
});

export const deleteRecipientError = message => ({
  type: RECIPIENT_DELETE_ERROR,
  payload: { message },
});

export const editRecipientRequest = (id, body, params) => ({
  type: RECIPIENT_EDIT_REQUEST,
  payload: { id, body, params },
});

export const editRecipientError = message => ({
  type: RECIPIENT_EDIT_ERROR,
  payload: { message },
});

export const undeleteRecipientRequest = (id, params) => ({
  type: RECIPIENT_UNDELETE_REQUEST,
  payload: { id, params },
});

export const undeleteRecipientError = message => ({
  type: RECIPIENT_UNDELETE_ERROR,
  payload: { message },
});

export const turnDriverRequest = (id, params) => ({
  type: RECIPIENT_TURN_DRIVER_REQUEST,
  payload: { id, params },
});

export const turnDriverError = message => ({
  type: RECIPIENT_TURN_DRIVER_ERROR,
  payload: { message },
});

export const turnDriverSuccess = () => ({
  type: RECIPIENT_TURN_DRIVER_SUCCESS,
});

export const turnDriverReset = () => ({
  type: RECIPIENT_TURN_DRIVER_RESET,
});

export const recipientPasswordRecoveryRequest = (id, body, params) => ({
  type: RECIPIENT_PASSWORD_RECOVERY_REQUEST,
  payload: { id, body, params },
});

export const recipientPasswordRecoveryError = message => ({
  type: RECIPIENT_PASSWORD_RECOVERY_ERROR,
  payload: { message },
});
