import api from './api';

export const allowAnywhereRefueling = ({
  userId,
  vehicleId,
  data,
  organizationId,
}) =>
  api.put(
    `/admin/organization/allow-anywhere-refueling?${
      userId ? 'userId' : 'vehicleId'
    }=${userId || vehicleId}&organizationId=${organizationId}`,
    data
  );
