import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import {
  DRIVER_LIST_REQUEST,
  DRIVER_REGISTER_REQUEST,
  DRIVER_DELETE_REQUEST,
  DRIVER_EDIT_REQUEST,
  DRIVER_UNDELETE_REQUEST,
  DRIVER_TURN_RECIPIENT_REQUEST,
  DRIVER_PASSWORD_RECOVERY_REQUEST,
  listDriverRequest,
  listDriverSuccess,
  listDriverError,
  registerDriverSuccess,
  registerDriverError,
  editDriverError,
  deleteDriverError,
  undeleteDriverError,
  turnRecipientError,
  DRIVER_REGISTER_IMPORTED_REQUEST,
  turnRecipientSuccess,
} from './actions';
import { getCurrentOrganization } from '../organizations/selectors';

function* listDriver(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const drivers = yield call(
    api.listDriver,
    APIParams({ ...payload.params, organizationId: currentOrganization.id })
  );

  if (drivers.ok) {
    const list = drivers.data.docs;
    const { current: currentPage, total: totalPages } = drivers.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = drivers.data.items;

    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listDriverSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(listDriverError(message));
  }
}

function* registerDriver(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const apiRequest = yield call(
    api.registerDriver,
    payload.body,
    currentOrganization.id
  );

  if (apiRequest.ok) {
    yield put(registerDriverSuccess());
  } else {
    yield put(registerDriverError(apiRequest.data));
  }
  yield put(listDriverRequest(payload.params));
}

function* registerDrivers(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const apiRequest = yield call(
    api.registerDrivers,
    payload.body,
    currentOrganization.id
  );

  if (apiRequest.ok) {
    yield put(registerDriverSuccess());
  } else {
    yield put(registerDriverError(apiRequest.data));
  }
  yield put(listDriverRequest(payload.params));
}

function* deleteDriver(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.deleteDriver,
    payload.id,
    currentOrganization.id
  );

  if (response.ok) {
    yield put(listDriverRequest(payload.params));
  } else {
    const message = 'Não foi possível remover o condutor';
    yield put(deleteDriverError(message));
  }
}

function* editDriver(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(api.editDriver, payload, currentOrganization.id);

  if (response.ok) {
    yield put(listDriverRequest(payload.params));
  } else {
    const message = 'Não foi possível editar o condutor';
    yield put(editDriverError(message));
  }
}

function* undeleteDriver(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.undeleteDriver,
    payload.id,
    currentOrganization.id
  );

  if (response.ok) {
    yield put(listDriverRequest(payload.params));
  } else {
    const message = 'Não foi possível recuperar o condutor';
    yield put(undeleteDriverError(message));
  }
}

function* turnRecipient(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.turnRecipient,
    payload.id,
    currentOrganization.id
  );

  if (response.ok) {
    yield put(turnRecipientSuccess());
    yield put(listDriverRequest(payload.params));
  } else {
    const message = 'Não foi possível transformar o condutor em beneficiário';
    yield put(turnRecipientError(message));
  }
}

function* passwordRecovery(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.passwordRecovery,
    payload,
    currentOrganization.id
  );

  if (response.ok) {
    yield put(listDriverRequest(payload.params));
  } else {
    const message = 'Não foi possível alterar a senha do condutor';
    yield put(turnRecipientError(message));
  }
}

export function* watchListDriverRequest(api) {
  yield takeEvery(DRIVER_LIST_REQUEST, listDriver, api);
}

export function* watchRegisterDriverRequest(api) {
  yield takeEvery(DRIVER_REGISTER_REQUEST, registerDriver, api);
}

function* watchDeleteDriverRequest(api) {
  yield takeEvery(DRIVER_DELETE_REQUEST, deleteDriver, api);
}

function* watchEditDriverRequest(api) {
  yield takeEvery(DRIVER_EDIT_REQUEST, editDriver, api);
}

function* watchUndeleteDriverRequest(api) {
  yield takeEvery(DRIVER_UNDELETE_REQUEST, undeleteDriver, api);
}

function* watchTurnRecipientRequest(api) {
  yield takeEvery(DRIVER_TURN_RECIPIENT_REQUEST, turnRecipient, api);
}

function* watchPasswordRecoveryRequest(api) {
  yield takeEvery(DRIVER_PASSWORD_RECOVERY_REQUEST, passwordRecovery, api);
}

function* watchRegisterImportedDriversRequest(api) {
  yield takeEvery(DRIVER_REGISTER_IMPORTED_REQUEST, registerDrivers, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListDriverRequest, api),
    fork(watchRegisterDriverRequest, api),
    fork(watchDeleteDriverRequest, api),
    fork(watchEditDriverRequest, api),
    fork(watchUndeleteDriverRequest, api),
    fork(watchTurnRecipientRequest, api),
    fork(watchPasswordRecoveryRequest, api),
    fork(watchRegisterImportedDriversRequest, api),
  ]);
}
