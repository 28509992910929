import produce from 'immer';

import { ACTIONS } from './actions';

const INIT_STATE = {
  isSuperAdmin: false,
  isAdmin: false,
  isExpenseCenterModule: false,
  hasFuelManagementModule: false,
  organizationsTree: [],
  organizations: [],
  currentPage: 1,
  totalPages: 0,
  totalItems: 0,
  skipItems: 0,
  currentOrganization: null,
  loading: false,
  error: null,
  creditTransfer: {
    loading: false,
    error: '',
    success: false,
  },
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ACTIONS.GET_ORGANIZATIONS_TREE: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case ACTIONS.GET_ORGANIZATIONS_TREE_SUCCESS: {
        draft.loading = false;
        draft.organizationsTree = action.payload.organizationsTree;
        draft.error = null;
        break;
      }

      case ACTIONS.GET_ORGANIZATIONS_TREE_ERROR: {
        draft.loading = false;
        draft.organizationsTree = [];
        draft.error = action.payload.message;
        break;
      }

      case ACTIONS.GET_ORGANIZATIONS: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case ACTIONS.ON_SUCCESS: {
        draft.loading = false;
        draft.organizations = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = null;
        break;
      }

      case ACTIONS.ON_ERROR: {
        draft.loading = false;
        draft.organizations = [];
        draft.error = action.payload.message;
        break;
      }

      case ACTIONS.ENABLE_ADMIN: {
        draft.isAdmin = true;
        break;
      }

      case ACTIONS.ENABLE_SUPER_ADMIN: {
        draft.isSuperAdmin = true;
        break;
      }

      case ACTIONS.SELECT_ORGANIZATION: {
        draft.currentOrganization = action.payload.currentOrganization;
        break;
      }

      case ACTIONS.DESELECT_ORGANIZATION: {
        draft.currentOrganization = null;
        break;
      }

      case ACTIONS.CLEAR_ORGANIZATIONS: {
        draft.organizations = [];
        draft.currentOrganization = null;
        draft.isSuperAdmin = false;
        break;
      }

      case ACTIONS.CREATE_ORGANIZATION: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case ACTIONS.EDIT_ORGANIZATION: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case ACTIONS.DELETE_ORGANIZATION: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case ACTIONS.CREDIT_TRANSFER: {
        draft.creditTransfer.loading = true;
        draft.creditTransfer.error = '';
        draft.creditTransfer.success = false;
        break;
      }

      case ACTIONS.CREDIT_TRANSFER_SUCCESS: {
        draft.creditTransfer.loading = false;
        draft.creditTransfer.success = true;
        draft.creditTransfer.error = '';
        break;
      }

      case ACTIONS.CREDIT_TRANSFER_ERROR: {
        draft.creditTransfer.loading = false;
        draft.creditTransfer.success = false;
        draft.creditTransfer.error = action.payload.message;
        break;
      }

      case ACTIONS.CREDIT_TRANSFER_RESET: {
        draft.creditTransfer.loading = false;
        draft.creditTransfer.success = false;
        draft.creditTransfer.error = '';
        break;
      }

      case ACTIONS.SET_EXPENSE_CENTER: {
        draft.isExpenseCenterModule = action.payload;
        break;
      }

      case ACTIONS.SET_FUEL_MANAGEMENT_MODULE: {
        draft.hasFuelManagementModule = action.payload;
        break;
      }

      default:
        return state;
    }
  });
};
