import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const getUsers = params =>
  api.get(`/admin/organization/user${objectToURLParams(params)}`);

export const getUserPermission = parameters =>
  api.get(
    `/admin/organization/user/permissions${objectToURLParams(parameters)}`
  );

export const createUserPermission = payload =>
  api.post('/admin/organization/user/permission', { ...payload });

export const updateUserPermission = (id, status) =>
  api.put(`/admin/organization/user/permission/${id}`, { status });
