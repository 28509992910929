export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_ERROR = 'USER_LIST_ERROR';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CHANGE_PASSWORD_RESET = 'CHANGE_PASSWORD_RESET';
export const VALIDATE_SESSION_REQUEST = 'VALIDATE_SESSION_REQUEST';
export const VALIDATE_SESSION_SUCCESS = 'VALIDATE_SESSION_SUCCESS';
export const VALIDATE_SESSION_ERROR = 'VALIDATE_SESSION_ERROR';

export const listUser = params => ({
  type: USER_LIST_REQUEST,
  payload: { params },
});

export const listUserSuccess = payload => ({
  type: USER_LIST_SUCCESS,
  payload,
});

export const listUserError = message => ({
  type: USER_LIST_ERROR,
  payload: { message },
});

export const changePasswordRequest = ({ password, newPassword }) => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload: { password, newPassword },
});

export const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordError = message => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: { message },
});

export const validateSessionRequest = () => ({
  type: VALIDATE_SESSION_REQUEST,
});

export const validateSessionSuccess = () => ({
  type: VALIDATE_SESSION_SUCCESS,
});

export const validateSessionError = message => ({
  type: VALIDATE_SESSION_ERROR,
  payload: { message },
});
