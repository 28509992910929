export const BILL_LIST_REQUEST = 'BILL_LIST_REQUEST';
export const BILL_LIST_SUCCESS = 'BILL_LIST_SUCCESS';
export const BILL_LIST_ERROR = 'BILL_LIST_ERROR';
export const BILLS_PER_PERIOD = 'BILLS_PER_PERIOD';
export const BILLS_PER_PERIOD_SUCCESS = 'BILLS_PER_PERIOD_SUCCESS';
export const BILLS_PER_PERIOD_ERROR = 'BILLS_PER_PERIOD_ERROR';

export const listResumes = () => ({
  type: BILL_LIST_REQUEST,
});

export const onListResumesSuccess = data => ({
  type: BILL_LIST_SUCCESS,
  payload: { data },
});

export const onListResumesError = message => ({
  type: BILL_LIST_ERROR,
  payload: { message },
});

export const listPerPeriod = params => ({
  type: BILLS_PER_PERIOD,
  payload: { params },
});

export const onListPerPeriodSuccess = data => ({
  type: BILLS_PER_PERIOD_SUCCESS,
  payload: { data },
});

export const onListPerPeriodError = message => ({
  type: BILLS_PER_PERIOD_ERROR,
  payload: { message },
});
