import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';

import { getCurrentOrganization } from 'redux/organizations/selectors';
import {
  PURCHASE_LIST_REQUEST,
  PURCHASE_CREATE_REQUEST,
  PURCHASE_LIST_ALL_REQUEST,
  listPurchase as listPurchaseAction,
  listAllPurchase as listAllPurchaseAction,
  listPurchaseSuccess,
  listPurchaseError,
  createPurchaseSuccess,
  createPurchaseError,
  listAllPurchaseSuccess,
  listAllPurchaseError,
} from './actions';

function* listPurchase(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.list,
    APIParams({ ...payload.params, organizationId: currentOrganization.id })
  );

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(
      listPurchaseSuccess({
        list,
        pages,
        items,
        balance: response.data.balance,
      })
    );
  } else {
    const message = 'Não foi possível carregar a lista de compras';

    yield put(listPurchaseError(message));
  }
}

function* createPurchase(api, { payload }) {
  const response = yield call(api.create, payload.data);

  if (response.ok) {
    yield put(createPurchaseSuccess(response.data.value));
    if (payload.params.organizationIds) {
      yield put(listAllPurchaseAction(payload.params));
    } else {
      yield put(listPurchaseAction(payload.params));
    }
  } else {
    const message = 'Não foi possível criar este registro';

    yield put(createPurchaseError(message));
  }
}

function* listAllPurchase(api, { payload }) {
  const response = yield call(api.list, APIParams(payload.params));

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(
      listAllPurchaseSuccess({
        list,
        pages,
        items,
        balance: response.data.balance,
      })
    );
  } else {
    const message = 'Não foi possível carregar a lista de compras';

    yield put(listAllPurchaseError(message));
  }
}

export function* watchListPurchase(api) {
  yield takeEvery(PURCHASE_LIST_REQUEST, listPurchase, api);
}

export function* watchCreatePurchase(api) {
  yield takeEvery(PURCHASE_CREATE_REQUEST, createPurchase, api);
}

export function* watchListAllPurchase(api) {
  yield takeEvery(PURCHASE_LIST_ALL_REQUEST, listAllPurchase, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListPurchase, api),
    fork(watchCreatePurchase, api),
    fork(watchListAllPurchase, api),
  ]);
}
