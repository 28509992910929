import { history } from '../../App';

const logoutMonitor = response => {
  if (response.status === 401) {
    history.push('/user/logoff');
    window.location.reload();
  }
};

export default logoutMonitor;
