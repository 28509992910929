import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const listContainer = parameters =>
  api.get(`/admin/organization/container${objectToURLParams(parameters)}`);

export const registerContainer = (body, organizationId) =>
  api.post(`/admin/organization/container?organizationId=${organizationId}`, {
    ...body,
  });

export const editContainer = (id, body, organizationId) =>
  api.put(
    `/admin/organization/container/${id}?organizationId=${organizationId}`,
    { ...body }
  );

export const deleteContainer = (id, organizationId) =>
  api.delete(
    `/admin/organization/container/${id}?organizationId=${organizationId}`
  );

export const undeleteContainer = id => api.put(`/vehicle/undelete/${id}`);
