export const REPORTS_LIST_REQUEST = 'REPORTS_LIST_REQUEST';
export const REPORTS_LIST_SUCCESS = 'REPORTS_LIST_SUCCESS';
export const REPORTS_LIST_ERROR = 'REPORTS_LIST_ERROR';

export const REPORTS_BALANCE_SHARING_REQUEST =
  'REPORTS_BALANCE_SHARING_REQUEST';
export const REPORTS_BALANCE_SHARING_SUCCESS =
  'REPORTS_BALANCE_SHARING_SUCCESS';
export const REPORTS_BALANCE_SHARING_ERROR = 'REPORTS_BALANCE_SHARING_ERROR';

export const listReports = params => ({
  type: REPORTS_LIST_REQUEST,
  payload: { params },
});

export const listReportsSuccess = payload => ({
  type: REPORTS_LIST_SUCCESS,
  payload,
});

export const listReportsError = message => ({
  type: REPORTS_LIST_ERROR,
  payload: { message },
});

export const listBalanceSharingRequest = params => ({
  type: REPORTS_BALANCE_SHARING_REQUEST,
  payload: { params },
});

export const listBalanceSharingSuccess = payload => ({
  type: REPORTS_BALANCE_SHARING_SUCCESS,
  payload,
});
