import * as types from './types';

export const balanceRequest = params => ({
  type: types.DISTRIBUTION.REQUEST,
  payload: { params },
});

export const balanceSuccess = data => ({
  type: types.DISTRIBUTION.SUCCESS,
  payload: { data },
});

export const balanceError = error => ({
  type: types.DISTRIBUTION.ERROR,
  payload: { error },
});

export const updateBalance = data => ({
  type: types.UPDATE.REQUEST,
  payload: { data },
});

export const updateBalanceSuccess = () => ({
  type: types.UPDATE.SUCCESS,
});

export const updateBalanceError = error => ({
  type: types.UPDATE.ERROR,
  payload: { error },
});

export const shareBalance = (userId, status) => ({
  type: types.TOGGLE_SHARE,
  payload: { userId, status },
});

export const collectCredit = userId => ({
  type: types.COLLECT_CREDIT.REQUEST,
  payload: { userId },
});

export const collectCreditSuccess = () => ({
  type: types.COLLECT_CREDIT.SUCCESS,
});

export const collectCreditError = error => ({
  type: types.COLLECT_CREDIT.ERROR,
  payload: { error },
});

export const allowAnywhereRefueling = (userId, data) => ({
  type: types.TOGGLE_ANYWHERE_REFUELING,
  payload: { userId, data },
});
