import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: 'litroz',
      storage,
      whitelist: [
        'auth',
        'user',
        'organizations',
        'hydrate',
        'permissions',
        'menu',
        'subscription',
      ],
    },
    reducers
  );

  return persistedReducer;
};
