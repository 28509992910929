import * as types from '../types';

export const vehicleBalanceRequest = params => ({
  type: types.VEHICLE_DISTRIBUTION.REQUEST,
  payload: { params },
});

export const vehicleBalanceSuccess = data => ({
  type: types.VEHICLE_DISTRIBUTION.SUCCESS,
  payload: { data },
});

export const vehicleBalanceError = error => ({
  type: types.VEHICLE_DISTRIBUTION.ERROR,
  payload: { error },
});

export const updateVehicleBalance = data => ({
  type: types.VEHICLE_UPDATE.REQUEST,
  payload: { data },
});

export const updateVehicleBalanceSuccess = () => ({
  type: types.VEHICLE_UPDATE.SUCCESS,
});

export const updateVehicleBalanceError = error => ({
  type: types.VEHICLE_UPDATE.ERROR,
  payload: { error },
});

export const shareVehicleBalance = (id, status) => ({
  type: types.VEHICLE_TOGGLE_SHARE,
  payload: { id, status },
});

export const collectVehicleCredit = id => ({
  type: types.VEHICLE_COLLECT_CREDIT.REQUEST,
  payload: { id },
});

export const collectVehicleCreditSuccess = () => ({
  type: types.VEHICLE_COLLECT_CREDIT.SUCCESS,
});

export const collectVehicleCreditError = error => ({
  type: types.VEHICLE_COLLECT_CREDIT.ERROR,
  payload: { error },
});

export const allowAnywhereVehicleRefueling = (vehicleId, data) => ({
  type: types.TOGGLE_ANYWHERE_VEHICLE_REFUELING,
  payload: { vehicleId, data },
});
