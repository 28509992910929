import { all, select, put, call, takeEvery, fork } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';

import { USER_LIST_REQUEST } from '../actions';

import {
  listUserSuccess,
  listUserError,
  changePasswordSuccess,
  CHANGE_PASSWORD_REQUEST,
  changePasswordError,
  validateSessionSuccess,
  validateSessionError,
  VALIDATE_SESSION_REQUEST,
} from './actions';

import { getCurrentOrganization } from '../organizations/selectors';

function* listUsers(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);

  const users = yield call(
    api.users.list,
    APIParams({
      ...payload.params,
      organizationId: currentOrganization.id,
    })
  );

  if (users.ok) {
    const list = users.data.docs;
    const { current: currentPage, total: totalPages } = users.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = users.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listUserSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(listUserError(message));
  }
}

function* changeUserPassword(api, { payload }) {
  const response = yield call(api.changePassword, payload);

  if (response.ok) {
    yield put(changePasswordSuccess());
  } else {
    yield put(changePasswordError(response.data.message));
  }
}

function* validateUserSession(api) {
  const response = yield call(api.validateSession);

  if (response.ok) {
    yield put(validateSessionSuccess());
  } else {
    yield put(validateSessionError(response.data.message));
  }
}

export function* watchListTransactions(api) {
  yield takeEvery(USER_LIST_REQUEST, listUsers, api);
}

export function* watchChangePassword(api) {
  yield takeEvery(CHANGE_PASSWORD_REQUEST, changeUserPassword, api);
}

export function* watchValidateSession(api) {
  yield takeEvery(VALIDATE_SESSION_REQUEST, validateUserSession, api);
}

export default function* rootSaga(api) {
  yield all([fork(watchChangePassword, api), fork(watchValidateSession, api)]);
}
