import { persistStore } from 'redux-persist';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import persistReducers from './persistReducers';
import reducers from './reducers';
import sagas from './sagas';

const sagaMonitor =
  process.env.NODE_ENV === 'development'
    ? window.console.tron.createSagaMonitor()
    : null;

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

const middlewares = [sagaMiddleware];

export function configureStore(initialState) {
  const enhancers =
    process.env.NODE_ENV === 'development'
      ? compose(
          window.console.tron.createEnhancer(),
          applyMiddleware(...middlewares)
        )
      : compose(applyMiddleware(...middlewares));

  const store = createStore(persistReducers(reducers), initialState, enhancers);
  const persistor = persistStore(store);

  sagaMiddleware.run(sagas);

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('./reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  return { store, persistor };
}
