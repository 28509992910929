import produce from 'immer';

import {
  REPORTS_LIST_REQUEST,
  REPORTS_LIST_SUCCESS,
  REPORTS_LIST_ERROR,
} from './actions';

const INIT_STATE = {
  list: [],
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case REPORTS_LIST_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case REPORTS_LIST_SUCCESS: {
        draft.loading = false;
        draft.list = action.payload.list;
        draft.error = '';
        break;
      }

      case REPORTS_LIST_ERROR: {
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      }

      default:
        return state;
    }
  });
};
