export const CONTAINER_LIST_REQUEST = 'CONTAINER_LIST_REQUEST';
export const CONTAINER_LIST_SUCCESS = 'CONTAINER_LIST_SUCCESS';
export const CONTAINER_LIST_ERROR = 'CONTAINER_LIST_ERROR';

export const CONTAINER_REGISTER_REQUEST = 'CONTAINER_REGISTER_REQUEST';
export const CONTAINER_REGISTER_SUCCESS = 'CONTAINER_REGISTER_SUCCESS';
export const CONTAINER_REGISTER_ERROR = 'CONTAINER_REGISTER_ERROR';

export const CONTAINER_DELETE_REQUEST = 'CONTAINER_DELETE_REQUEST';
export const CONTAINER_DELETE_ERROR = 'CONTAINER_DELETE_ERROR';

export const CONTAINER_EDIT_REQUEST = 'CONTAINER_EDIT_REQUEST';
export const CONTAINER_EDIT_ERROR = 'CONTAINER_EDIT_ERROR';

export const CONTAINER_UNDELETE_REQUEST = 'CONTAINER_UNDELETE_REQUEST';
export const CONTAINER_UNDELETE_ERROR = 'CONTAINER_UNDELETE_ERROR';

export const listContainerRequest = params => ({
  type: CONTAINER_LIST_REQUEST,
  payload: { params },
});

export const listContainerSuccess = payload => ({
  type: CONTAINER_LIST_SUCCESS,
  payload,
});

export const listContainerError = message => ({
  type: CONTAINER_LIST_ERROR,
  payload: { message },
});

export const registerContainerRequest = (body, organizationId, params) => ({
  type: CONTAINER_REGISTER_REQUEST,
  payload: { body, organizationId, params },
});

export const registerContainerSuccess = () => ({
  type: CONTAINER_REGISTER_SUCCESS,
});

export const registerContainerError = message => ({
  type: CONTAINER_REGISTER_ERROR,
  payload: { message },
});

export const deleteContainerRequest = (id, organizationId, params) => ({
  type: CONTAINER_DELETE_REQUEST,
  payload: { id, organizationId, params },
});

export const deleteContainerError = message => ({
  type: CONTAINER_DELETE_ERROR,
  payload: { message },
});

export const editContainerRequest = (id, body, organizationId, params) => ({
  type: CONTAINER_EDIT_REQUEST,
  payload: { id, body, organizationId, params },
});

export const editContainerError = message => ({
  type: CONTAINER_EDIT_ERROR,
  payload: { message },
});

export const undeleteContainerRequest = (id, params) => ({
  type: CONTAINER_UNDELETE_REQUEST,
  payload: { id, params },
});

export const undeleteContainerError = message => ({
  type: CONTAINER_UNDELETE_ERROR,
  payload: { message },
});
