export const SUBSCRIPTION_REQUEST = 'SUBSCRIPTION_REQUEST';
export const SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTION_ERROR = 'SUBSCRIPTION_ERROR';

export const subscriptionRequest = () => ({
  type: SUBSCRIPTION_REQUEST,
});

export const subscriptionSuccess = data => ({
  type: SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const subscriptionError = message => ({
  type: SUBSCRIPTION_ERROR,
  payload: { message },
});
