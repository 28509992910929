import produce from 'immer';

import {
  STATION_LIST_REQUEST,
  STATION_LIST_SUCCESS,
  STATION_LIST_ERROR,
  TOGGLE_STATION,
  TOGGLE_STATION_ERROR,
  TOGGLE_STATION_SUCCESS,
} from './actions';

const INIT_STATE = {
  list: [],
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case STATION_LIST_REQUEST:
        draft.loading = true;
        draft.error = '';
        break;

      case STATION_LIST_SUCCESS:
        draft.loading = false;
        draft.list = action.payload.data;
        draft.error = '';
        break;

      case STATION_LIST_ERROR:
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;

      case TOGGLE_STATION:
        draft.error = '';
        break;

      case TOGGLE_STATION_ERROR:
        draft.error = action.payload.message;
        break;

      case TOGGLE_STATION_SUCCESS:
        draft.list = draft.list.map(station => {
          if (station.id !== action.payload.data.gasStationId) {
            return station;
          }
          return { ...station, enabled: !station.enabled };
        });
        break;

      default:
        return state;
    }
  });
};
