import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import {
  ADMINISTRATORS_LIST_REQUEST,
  REGISTER_ADMINISTRATOR,
  DELETE_ADMINISTRATOR,
  EDIT_ADMINISTRATOR,
  EDIT_ADMINISTRATOR_BRANCH,
  editAdministratorError,
  editAdministratorBranchError,
  registerAdministratorSuccess,
  deleteAdministratorError,
  listAdministrators as listAdministratorsAction,
  listAdministratorsSuccess,
  listAdministratorsError,
} from './actions';
import { getCurrentOrganization } from 'redux/organizations/selectors';

function* listAdministrators(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);

  if (currentOrganization && currentOrganization.id && currentOrganization.id !== 0) {
    payload.params.organizationId = currentOrganization.id;
  } else if ((!currentOrganization || currentOrganization.id === 0) && payload.params.orgUserName) {
    payload.params.userName = payload.params.orgUserName;
    delete payload.params.orgUserName;
  }

  const administrators = yield call(
    api.listAdminstrators,
    APIParams({ ...payload.params })
  );

  if (administrators.ok) {
    const list = administrators.data.docs;
    const {
      current: currentPage,
      total: totalPages,
    } = administrators.data.pages;

    const {
      total: totalItems,
      skip: skipItems = 0,
    } = administrators.data.items;

    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listAdministratorsSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(listAdministratorsError(message));
  }
}

function* registerAdministrator(api, { payload }) {
  const apiRequest = yield call(api.registerAdministrator, payload.body);
  if (apiRequest.ok) {
    yield put(registerAdministratorSuccess());
  } else {
    yield put(listAdministratorsError(apiRequest.data));
  }
  yield put(listAdministratorsAction(payload.params));
}

function* deleteAdministrator(api, { payload }) {
  const response = yield call(api.deleteAdministrator, payload.id);

  if (response.ok) {
    yield put(listAdministratorsAction(payload.params));
  } else {
    const message = 'Não foi possível remover o administrador';
    yield put(deleteAdministratorError(message));
  }
}

function* editAdministrator(api, { payload }) {
  const response = yield call(api.editAdministrator, payload);

  if (response.ok) {
    yield put(listAdministratorsAction(payload.params));
  } else {
    const message = 'Não foi possível editar o administrador';
    yield put(editAdministratorError(message));
  }
}

function* editAdministratorBranch(api, { payload }) {
  const response = yield call(api.editAdministratorBranch, payload);

  if (response.ok) {
    yield put(listAdministratorsAction(payload.params));
  } else {
    const message = 'Não foi possível editar o administrador';
    yield put(editAdministratorBranchError(message));
  }
}

export function* watchListAdministrators(api) {
  yield takeEvery(ADMINISTRATORS_LIST_REQUEST, listAdministrators, api);
}

export function* watchRegisterAdministrator(api) {
  yield takeEvery(REGISTER_ADMINISTRATOR, registerAdministrator, api);
}

function* watchDelete(api) {
  yield takeEvery(DELETE_ADMINISTRATOR, deleteAdministrator, api);
}

function* watchEdit(api) {
  yield takeEvery(EDIT_ADMINISTRATOR, editAdministrator, api);
}

function* watchEditBranch(api) {
  yield takeEvery(EDIT_ADMINISTRATOR_BRANCH, editAdministratorBranch, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListAdministrators, api),
    fork(watchRegisterAdministrator, api),
    fork(watchDelete, api),
    fork(watchEdit, api),
    fork(watchEditBranch, api),
  ]);
}
