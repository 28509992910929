import api from './api';

export const login = data => api.post('login', data);

export const setHeader = (name, value) => api.setHeader(name, value);

export const forgetPassword = identifier =>
  api.post('login/forgot', { identifier });

export const resetPassword = (token, password) =>
  api.post('login/reset', { token, password });
