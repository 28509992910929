export const EXPENSE_CENTER_LIST_REQUEST = 'EXPENSE_CENTER_LIST_REQUEST';
export const EXPENSE_CENTER_LIST_SUCCESS = 'EXPENSE_CENTER_LIST_SUCCESS';
export const EXPENSE_CENTER_LIST_ERROR = 'EXPENSE_CENTER_LIST_ERROR';
export const EXPENSE_CENTER_CREATE_REQUEST = 'EXPENSE_CENTER_CREATE_REQUEST';
export const EXPENSE_CENTER_CREATE_SUCCESS = 'EXPENSE_CENTER_CREATE_SUCCESS';
export const EXPENSE_CENTER_CREATE_ERROR = 'EXPENSE_CENTER_CREATE_ERROR';
export const EXPENSE_CENTER_UPDATE_REQUEST = 'EXPENSE_CENTER_UPDATE_REQUEST';
export const EXPENSE_CENTER_UPDATE_SUCCESS = 'EXPENSE_CENTER_UPDATE_SUCCESS';
export const EXPENSE_CENTER_UPDATE_ERROR = 'EXPENSE_CENTER_UPDATE_ERROR';
export const EXPENSE_CENTER_DELETE_REQUEST = 'EXPENSE_CENTER_DELETE_REQUEST';
export const EXPENSE_CENTER_DELETE_SUCCESS = 'EXPENSE_CENTER_DELETE_SUCCESS';
export const EXPENSE_CENTER_DELETE_ERROR = 'EXPENSE_CENTER_DELETE_ERROR';
export const EXPENSE_CENTER_RESET_STATE = 'EXPENSE_CENTER_RESET';
export const EXPENSE_CENTER_UNDELETE_REQUEST =
  'EXPENSE_CENTER_UNDELETE_REQUEST';
export const EXPENSE_CENTER_UNDELETE_SUCCESS =
  'EXPENSE_CENTER_UNDELETE_SUCCESS';
export const EXPENSE_CENTER_UNDELETE_ERROR = 'EXPENSE_CENTER_UNDELETE_ERROR';

export const listExpenseCentersRequest = params => ({
  type: EXPENSE_CENTER_LIST_REQUEST,
  payload: { params },
});

export const listExpenseCentersSuccess = payload => ({
  type: EXPENSE_CENTER_LIST_SUCCESS,
  payload,
});

export const listExpenseCentersError = message => ({
  type: EXPENSE_CENTER_LIST_ERROR,
  payload: { message },
});

export const createExpenseCenterRequest = (body, params) => ({
  type: EXPENSE_CENTER_CREATE_REQUEST,
  payload: { body, params },
});

export const createExpenseCenterSuccess = payload => ({
  type: EXPENSE_CENTER_CREATE_SUCCESS,
  payload,
});

export const createExpenseCenterError = message => ({
  type: EXPENSE_CENTER_CREATE_ERROR,
  payload: { message },
});

export const updateExpenseCenterRequest = (id, body, params) => ({
  type: EXPENSE_CENTER_UPDATE_REQUEST,
  payload: { id, body, params },
});

export const updateExpenseCenterSuccess = payload => ({
  type: EXPENSE_CENTER_UPDATE_SUCCESS,
  payload,
});

export const updateExpenseCenterError = message => ({
  type: EXPENSE_CENTER_UPDATE_ERROR,
  payload: { message },
});

export const deleteExpenseCenterRequest = (id, params) => ({
  type: EXPENSE_CENTER_DELETE_REQUEST,
  payload: { id, params },
});

export const deleteExpenseCenterSuccess = payload => ({
  type: EXPENSE_CENTER_DELETE_SUCCESS,
  payload,
});

export const deleteExpenseCenterError = message => ({
  type: EXPENSE_CENTER_DELETE_ERROR,
  payload: { message },
});

export const resetExpenseCenterState = () => ({
  type: EXPENSE_CENTER_RESET_STATE,
});

export const undeleteExpenseCenterRequest = (id, params) => ({
  type: EXPENSE_CENTER_UNDELETE_REQUEST,
  payload: { id, params },
});

export const undeleteExpenseCenterSuccess = payload => ({
  type: EXPENSE_CENTER_UNDELETE_SUCCESS,
  payload,
});

export const undeleteExpenseCenterError = message => ({
  type: EXPENSE_CENTER_UNDELETE_ERROR,
  payload: { message },
});
