import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const getUsersById = parameters =>
  api.get(`/admin/organization/user${objectToURLParams(parameters)}`);

export const getVehiclesById = parameters =>
  api.get(`/admin/organization/vehicle${objectToURLParams(parameters)}`);

export const listAdminstrators = parameters =>
  api.get(`/superAdmin/organization/user${objectToURLParams(parameters)}`);

export const registerAdministrator = body =>
  api.post('/superAdmin/organization/user', { ...body });

export const editAdministrator = payload =>
  api.put(`/superAdmin/organization/user/${payload.id}`, payload.body);

export const editAdministratorBranch = payload =>
  api.put(`/superAdmin/organization/userBranch/${payload.id}`, payload.body);

export const deleteAdministrator = id =>
  api.delete(`/superAdmin/organization/user/${id}`);
