import fetchJsonp from 'fetch-jsonp';
import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = payload =>
  api.get(`/superAdmin/organization${objectToURLParams(payload)}`);

export const listTree = payload =>
  api.get(`/v2/superAdmin/organization${objectToURLParams(payload)}`);

export const post = payload => api.post('/superAdmin/organization', payload);

export const put = (id, payload) =>
  api.put(`/superAdmin/organization/${id}`, payload);

export const remove = id => api.delete(`/superAdmin/organization/${id}`);

export const creditTransfer = (id, payload) =>
  api.post(`/superAdmin/organization/${id}/credit-transfer`, payload);

export const getDataByFederalTax = async federalTaxNumber => {
  const url = `https://www.receitaws.com.br/v1/cnpj/${federalTaxNumber.replace(
    /[./-]/g,
    ''
  )}`;

  const request = await fetchJsonp(url);
  const json = await request.json();
  return json;
};
