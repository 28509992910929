export const VEHICLE_LIST_REQUEST = 'VEHICLE_LIST_REQUEST';
export const VEHICLE_LIST_SUCCESS = 'VEHICLE_LIST_SUCCESS';
export const VEHICLE_LIST_ERROR = 'VEHICLE_LIST_ERROR';

export const VEHICLE_REGISTER_REQUEST = 'VEHICLE_REGISTER_REQUEST';
export const VEHICLE_REGISTER_SUCCESS = 'VEHICLE_REGISTER_SUCCESS';
export const VEHICLE_REGISTER_ERROR = 'VEHICLE_REGISTER_ERROR';

export const VEHICLE_DELETE_REQUEST = 'VEHICLE_DELETE_REQUEST';
export const VEHICLE_DELETE_ERROR = 'VEHICLE_DELETE_ERROR';

export const VEHICLE_EDIT_REQUEST = 'VEHICLE_EDIT_REQUEST';
export const VEHICLE_EDIT_ERROR = 'VEHICLE_EDIT_ERROR';

export const VEHICLE_UNDELETE_REQUEST = 'VEHICLE_UNDELETE_REQUEST';
export const VEHICLE_UNDELETE_ERROR = 'VEHICLE_UNDELETE_ERROR';

export const VEHICLE_REGISTER_IMPORTED_REQUEST =
  'VEHICLE_REGISTER_IMPORTED_REQUEST';

export const VEHICLE_REGISTER_IMPORTED_SUCCESS =
  'VEHICLE_REGISTER_IMPORTED_SUCCESS';

export const VEHICLE_REGISTER_IMPORTED_ERROR =
  'VEHICLE_REGISTER_IMPORTED_ERROR';

export const VEHICLE_REGISTER_IMPORTED_RESET =
  'VEHICLE_REGISTER_IMPORTED_RESET';

export const listVehicleRequest = params => ({
  type: VEHICLE_LIST_REQUEST,
  payload: { params },
});

export const listVehicleSuccess = payload => ({
  type: VEHICLE_LIST_SUCCESS,
  payload,
});

export const listVehicleError = message => ({
  type: VEHICLE_LIST_ERROR,
  payload: { message },
});

export const registerVehicleRequest = (body, params) => ({
  type: VEHICLE_REGISTER_REQUEST,
  payload: { body, params },
});

export const registerVehicleSuccess = () => ({
  type: VEHICLE_REGISTER_SUCCESS,
});

export const registerVehicleError = message => ({
  type: VEHICLE_REGISTER_ERROR,
  payload: { message },
});

export const deleteVehicleRequest = (id, params) => ({
  type: VEHICLE_DELETE_REQUEST,
  payload: { id, params },
});

export const deleteVehicleError = message => ({
  type: VEHICLE_DELETE_ERROR,
  payload: { message },
});

export const editVehicleRequest = (id, body, params) => ({
  type: VEHICLE_EDIT_REQUEST,
  payload: { id, body, params },
});

export const editVehicleError = message => ({
  type: VEHICLE_EDIT_ERROR,
  payload: { message },
});

export const undeleteVehicleRequest = (id, params) => ({
  type: VEHICLE_UNDELETE_REQUEST,
  payload: { id, params },
});

export const undeleteVehicleError = message => ({
  type: VEHICLE_UNDELETE_ERROR,
  payload: { message },
});

export const registerVehicleImportedRequest = (body, params) => ({
  type: VEHICLE_REGISTER_IMPORTED_REQUEST,
  payload: { body, params },
});

export const registerVehicleImportedSuccess = () => ({
  type: VEHICLE_REGISTER_IMPORTED_SUCCESS,
});

export const registerVehicleImportedError = message => ({
  type: VEHICLE_REGISTER_IMPORTED_ERROR,
  payload: { message },
});

export const registerVehicleReset = () => ({
  type: VEHICLE_REGISTER_IMPORTED_RESET,
});
