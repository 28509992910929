import apisauce from 'apisauce';

class API {
  constructor() {
    this.api = apisauce.create({
      baseURL: process.env.REACT_APP_API,
      headers: { 'Content-Type': 'application/json' },
      timeout: 180 * 1000,
    });
  }
}

const apiInstance = new API();
export default apiInstance.api;
