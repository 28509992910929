import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/organization/bill${objectToURLParams(parameters)}`);

export const listPerPeriod = parameters =>
  api.get(
    `/admin/organization/bill-per-period${objectToURLParams(parameters)}`
  );
