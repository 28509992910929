import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(
    `/admin/organization/transaction/report${objectToURLParams(parameters)}`
  );

export const balanceSharing = parameters =>
  api.get(`/admin/organization/balancesharing${objectToURLParams(parameters)}`);

export const efficiencyHistory = (id, parameters) =>
  api.get(
    `/admin/organization/vehicle/${id}/efficiency${objectToURLParams(
      parameters
    )}`
  );
