import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';

import {
  listFuelManagementUsersSuccess,
  listFuelManagementUsersError,
  FUEL_MANAGEMENT_USERS_LIST_REQUEST,
} from './actions';

function* listFuelManagementUsers(api, { payload }) {
  const response = yield call(api.listUsers, APIParams({ ...payload.params }));

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listFuelManagementUsersSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(listFuelManagementUsersError(message));
  }
}

export function* watchListFuelManagementUsers(api) {
  yield takeEvery(
    FUEL_MANAGEMENT_USERS_LIST_REQUEST,
    listFuelManagementUsers,
    api
  );
}

export default function* rootSaga(api) {
  yield all([fork(watchListFuelManagementUsers, api)]);
}
