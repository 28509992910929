import produce from 'immer';

import {
  BILL_LIST_SUCCESS,
  BILL_LIST_REQUEST,
  BILL_LIST_ERROR,
  BILLS_PER_PERIOD,
  BILLS_PER_PERIOD_ERROR,
  BILLS_PER_PERIOD_SUCCESS,
} from './actions';

const INIT_STATE = {
  bills: [],
  filteredBills: [],
  paymentFrequency: '',
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case BILL_LIST_REQUEST:
        draft.loading = true;
        draft.error = '';
        break;

      case BILL_LIST_SUCCESS:
        draft.loading = false;
        draft.bills = action.payload.data;
        draft.error = '';
        break;

      case BILL_LIST_ERROR:
        draft.loading = false;
        draft.bills = [];
        draft.error = action.payload.message;
        break;

      case BILLS_PER_PERIOD:
        draft.loading = true;
        draft.error = '';
        break;

      case BILLS_PER_PERIOD_SUCCESS:
        draft.loading = false;
        draft.filteredBills = action.payload.data;
        draft.paymentFrequency = action.payload.data[0]?.paymentFrequency || '';
        draft.error = '';
        break;

      case BILLS_PER_PERIOD_ERROR:
        draft.loading = false;
        draft.filteredBills = [];
        draft.error = action.payload.message;
        break;

      default:
        return state;
    }
  });
};
