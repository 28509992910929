import produce from 'immer';

import { HYDRATE_REQUEST, HYDRATE_SUCCESS, HYDRATE_ERROR } from './actions';

const INIT_STATE = {
  data: {},
  loading: false,
  error: '',
  isVehicleBalance: false,
  isEmployeeBalance: true,
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case HYDRATE_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case HYDRATE_SUCCESS: {
        draft.loading = false;
        draft.data = action.payload.data;
        draft.isEmployeeBalance = action.payload.isEmployeeBalance;
        draft.isVehicleBalance = action.payload.isVehicleBalance;
        draft.error = '';
        break;
      }

      case HYDRATE_ERROR: {
        draft.loading = false;
        draft.data = {};
        draft.error = action.payload.message;
        break;
      }

      default:
        return state;
    }
  });
};
