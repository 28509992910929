export const STATION_LIST_REQUEST = 'STATION_LIST_REQUEST';
export const STATION_LIST_SUCCESS = 'STATION_LIST_SUCCESS';
export const STATION_LIST_ERROR = 'STATION_LIST_ERROR';
export const TOGGLE_STATION = 'STATION_TOGGLE';
export const TOGGLE_STATION_ERROR = 'STATION_TOGGLE_ERROR';
export const TOGGLE_STATION_SUCCESS = 'STATION_TOGGLE_SUCCESS';

export const list = params => ({
  type: STATION_LIST_REQUEST,
  payload: { params },
});

export const onListSuccess = data => ({
  type: STATION_LIST_SUCCESS,
  payload: { data },
});

export const onListError = message => ({
  type: STATION_LIST_ERROR,
  payload: { message },
});

export const toggleStatus = data => ({
  type: TOGGLE_STATION,
  payload: { data },
});

export const onToggleSuccess = data => ({
  type: TOGGLE_STATION_SUCCESS,
  payload: { data },
});

export const onToggleError = message => ({
  type: TOGGLE_STATION_ERROR,
  payload: { message },
});
