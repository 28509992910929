import produce from 'immer';

import {
  FUEL_MANAGEMENT_CREATE_WALLET,
  FUEL_MANAGEMENT_CREATE_WALLET_ERROR,
  FUEL_MANAGEMENT_CREATE_WALLET_SUCCESS,
  FUEL_MANAGEMENT_UPDATE_WALLET,
  FUEL_MANAGEMENT_UPDATE_WALLET_ERROR,
  FUEL_MANAGEMENT_UPDATE_WALLET_LIMIT,
  FUEL_MANAGEMENT_UPDATE_WALLET_LIMIT_ERROR,
  FUEL_MANAGEMENT_UPDATE_WALLET_LIMIT_SUCCESS,
  FUEL_MANAGEMENT_UPDATE_WALLET_SUCCESS,
  FUEL_MANAGEMENT_WALLETS_LIST_ERROR,
  FUEL_MANAGEMENT_WALLETS_LIST_REQUEST,
  FUEL_MANAGEMENT_WALLETS_LIST_SUCCESS,
  FUEL_MANAGEMENT_UPDATE_MULTIPLE_WALLETS,
  FUEL_MANAGEMENT_UPDATE_MULTIPLE_WALLETS_SUCCESS,
  FUEL_MANAGEMENT_UPDATE_MULTIPLE_WALLETS_ERROR,
  FUEL_MANAGEMENT_SHARE_BALANCE,
  FUEL_MANAGEMENT_SHARE_BALANCE_SUCCESS,
  FUEL_MANAGEMENT_SHARE_BALANCE_ERROR,
} from './actions';

const INITIAL_STATE = {
  list: [],
  balanceSharingType: '',
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: null,
  success: false,
  updating: false,
  selectedStation: {},
};

export default (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case FUEL_MANAGEMENT_WALLETS_LIST_REQUEST:
        draft.loading = true;
        draft.error = null;
        break;

      case FUEL_MANAGEMENT_WALLETS_LIST_SUCCESS:
        draft.loading = false;
        draft.list = action.payload.list;
        draft.balanceSharingType = action.payload.balanceSharingType;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = null;
        break;

      case FUEL_MANAGEMENT_WALLETS_LIST_ERROR:
        draft.loading = false;
        draft.list = [];
        draft.balanceSharingType = '';
        draft.error = action.payload.error;
        break;

      case FUEL_MANAGEMENT_CREATE_WALLET: {
        draft.error = null;
        break;
      }

      case FUEL_MANAGEMENT_CREATE_WALLET_SUCCESS: {
        draft.error = null;
        draft.list = action.payload.list;
        break;
      }

      case FUEL_MANAGEMENT_CREATE_WALLET_ERROR: {
        draft.error = action.payload.message;
        break;
      }

      case FUEL_MANAGEMENT_UPDATE_WALLET: {
        draft.error = null;
        break;
      }

      case FUEL_MANAGEMENT_UPDATE_WALLET_SUCCESS: {
        draft.error = null;
        draft.list = action.payload.list;
        break;
      }

      case FUEL_MANAGEMENT_UPDATE_WALLET_ERROR: {
        draft.error = action.payload.message;
        break;
      }

      case FUEL_MANAGEMENT_UPDATE_WALLET_LIMIT: {
        draft.updating = true;
        draft.updatingError = null;
        break;
      }

      case FUEL_MANAGEMENT_UPDATE_WALLET_LIMIT_SUCCESS:
        draft.updating = false;
        draft.updatingError = null;
        break;

      case FUEL_MANAGEMENT_UPDATE_WALLET_LIMIT_ERROR:
        draft.updating = false;
        draft.updatingError = action.payload.message;
        break;

      case FUEL_MANAGEMENT_UPDATE_MULTIPLE_WALLETS: {
        draft.error = null;
        break;
      }

      case FUEL_MANAGEMENT_UPDATE_MULTIPLE_WALLETS_SUCCESS: {
        draft.error = null;
        draft.list = action.payload.list;
        break;
      }

      case FUEL_MANAGEMENT_UPDATE_MULTIPLE_WALLETS_ERROR: {
        draft.error = action.payload.message;
        break;
      }

      case FUEL_MANAGEMENT_SHARE_BALANCE: {
        draft.error = null;
        break;
      }

      case FUEL_MANAGEMENT_SHARE_BALANCE_SUCCESS: {
        draft.error = null;
        draft.list = action.payload.list;
        break;
      }

      case FUEL_MANAGEMENT_SHARE_BALANCE_ERROR: {
        draft.error = action.payload.message;
        break;
      }

      default:
        return state;
    }
  });
};
