import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { QueryClientProvider, QueryClient } from 'react-query';
import * as serviceWorker from './serviceWorker';
import { configureStore } from './redux/store';
import { setupSentry } from './config/sentry';

setupSentry();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      cacheTime: 0,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

const { store, persistor } = configureStore();

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Suspense fallback={<div className="loading" />}>
          <App />
        </Suspense>
      </PersistGate>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root')
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
