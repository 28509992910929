import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/organization/deposit${objectToURLParams(parameters)}`);

export const listAttachments = parameters =>
  api.get(
    `/admin/organization/depositAttachment${objectToURLParams(parameters)}`
  );

export const downloadAttachment = parameters =>
  api.get(`/download${objectToURLParams(parameters)}`);
