import produce from 'immer';

import {
  PURCHASE_LIST_REQUEST,
  PURCHASE_LIST_SUCCESS,
  PURCHASE_LIST_ERROR,
  PURCHASE_CREATE_REQUEST,
  PURCHASE_CREATE_SUCCESS,
  PURCHASE_CREATE_ERROR,
  CLEAR_META_DATA,
  PURCHASE_LIST_ALL_REQUEST,
  PURCHASE_LIST_ALL_SUCCESS,
  PURCHASE_LIST_ALL_ERROR,
} from './actions';

const INITIAL_STATE = {
  list: [],
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
  success: false,
  balance: '',
  meta: {},
};

export default (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case PURCHASE_LIST_REQUEST:
        draft.loading = true;
        draft.error = '';
        break;
      case PURCHASE_LIST_SUCCESS:
        draft.loading = false;
        draft.list = action.payload.list;
        draft.balance = action.payload.balance;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      case PURCHASE_LIST_ERROR:
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      case PURCHASE_CREATE_REQUEST:
        draft.loading = true;
        draft.error = '';
        draft.success = false;
        break;
      case PURCHASE_CREATE_SUCCESS:
        draft.loading = false;
        draft.meta = action.payload.meta;
        draft.error = '';
        draft.success = true;
        break;
      case PURCHASE_CREATE_ERROR:
        draft.loading = false;
        draft.error = action.payload.message;
        draft.success = false;
        break;
      case CLEAR_META_DATA:
        draft.meta = {};
        break;
      case PURCHASE_LIST_ALL_REQUEST:
        draft.loading = true;
        draft.error = '';
        break;
      case PURCHASE_LIST_ALL_SUCCESS:
        draft.loading = false;
        draft.list = action.payload.list;
        draft.balance = action.payload.balance;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      case PURCHASE_LIST_ALL_ERROR:
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      default:
        return state;
    }
  });
};
