import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/v2/admin/organization/settings${objectToURLParams(parameters)}`);

export const update = ({ body, organizationId }) =>
  api.put(
    `/admin/organization/settings?organizationId=${organizationId}`,
    body
  );
