import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const dsn =
  'https://bf78ab9647524869a9f63c074cf723e0@o278527.ingest.sentry.io/6110970';

export const setupSentry = () => {
  Sentry.init({
    dsn,
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  });
};
