import { APIParams } from 'helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as organizations from './actions';

function* list(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const userOrganizations = response.data.docs;

    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(
      organizations.onSuccess({ list: userOrganizations, pages, items })
    );
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(organizations.onError(message));
  }
}

function* listTree(api, { payload }) {
  const response = yield call(api.listTree, APIParams({ ...payload.params }));

  if (response.ok) {
    const userOrganizations = response.data;
    yield put(organizations.getOrganizationsTreeSuccess(userOrganizations));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(organizations.getOrganizationsTreeError(message));
  }
}

function* post(api, { payload }) {
  const response = yield call(api.post, payload.organization);

  if (response.ok) {
    yield put(organizations.getOrganizationsTree());
    yield put(organizations.getOrganizations(payload.params));
  } else {
    const message = 'Não foi possível criar a filial';
    yield put(organizations.onError(message));
  }
}

function* edit(api, { payload }) {
  const response = yield call(api.put, payload.id, payload.organization);

  if (response.ok) {
    yield put(organizations.getOrganizationsTree());
    yield put(organizations.getOrganizations(payload.params));
  } else {
    const message = 'Não foi possível editar a filial';
    yield put(organizations.onError(message));
  }
}

function* remove(api, { payload }) {
  const response = yield call(api.remove, payload.organization);

  if (response.ok) {
    yield put(organizations.getOrganizationsTree());
    yield put(organizations.getOrganizations(payload.params));
  } else {
    const message = 'Não foi possível remover a filial';
    yield put(organizations.onError(message));
  }
}

function* creditTransfer(api, { payload }) {
  const response = yield call(api.creditTransfer, payload.id, payload.data);
  if (
    response.data.message === 'Organizations have different payment periods'
  ) {
    const message =
      'Transferência de créditos não permitida para empresas com modalidades de faturamento distintas';
    yield put(organizations.creditTransferError(message));
    return;
  }

  if (response.ok) {
    yield put(organizations.creditTransferSuccess());
    yield put(organizations.getOrganizations(payload.params));
  } else {
    const message = 'Não foi possível transferir créditos';
    yield put(organizations.creditTransferError(message));
  }
}

function* watchList(api) {
  yield takeEvery(organizations.ACTIONS.GET_ORGANIZATIONS, list, api);
}

function* watchListTree(api) {
  yield takeEvery(organizations.ACTIONS.GET_ORGANIZATIONS_TREE, listTree, api);
}

function* watchPost(api) {
  yield takeEvery(organizations.ACTIONS.CREATE_ORGANIZATION, post, api);
}

function* watchPut(api) {
  yield takeEvery(organizations.ACTIONS.EDIT_ORGANIZATION, edit, api);
}

function* watchDelete(api) {
  yield takeEvery(organizations.ACTIONS.DELETE_ORGANIZATION, remove, api);
}

function* watchCreditTransfer(api) {
  yield takeEvery(organizations.ACTIONS.CREDIT_TRANSFER, creditTransfer, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchList, api),
    fork(watchListTree, api),
    fork(watchPost, api),
    fork(watchPut, api),
    fork(watchDelete, api),
    fork(watchCreditTransfer, api),
  ]);
}
