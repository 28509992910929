export const HYDRATE_REQUEST = 'HYDRATE_REQUEST';
export const HYDRATE_SUCCESS = 'HYDRATE_SUCCESS';
export const HYDRATE_ERROR = 'HYDRATE_ERROR';

export const hydrateRequest = params => ({
  type: HYDRATE_REQUEST,
  payload: { params },
});

export const hydrateSuccess = payload => ({
  type: HYDRATE_SUCCESS,
  payload,
});

export const hydrateError = message => ({
  type: HYDRATE_ERROR,
  payload: { message },
});
