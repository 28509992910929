import produce from 'immer';

import {
  ORG_SETTINGS_LIST_REQUEST,
  ORG_SETTINGS_LIST_SUCCESS,
  ORG_SETTINGS_LIST_ERROR,
  ORG_SETTINGS_UPDATE_REQUEST,
  ORG_SETTINGS_UPDATE_ERROR,
  ORG_SETTINGS_UPDATE_SUCCESS,
  ORG_SETTINGS_RESET_STATE,
} from './actions';

const INIT_STATE = {
  list: [],
  loading: false,
  error: '',
  success: false,
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ORG_SETTINGS_LIST_REQUEST:
        draft.loading = true;
        draft.error = '';
        break;

      case ORG_SETTINGS_LIST_SUCCESS:
        draft.loading = false;
        draft.list = action.payload.list;
        draft.error = '';
        break;

      case ORG_SETTINGS_LIST_ERROR:
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;

      case ORG_SETTINGS_UPDATE_REQUEST:
        draft.loading = true;
        draft.error = '';
        break;

      case ORG_SETTINGS_UPDATE_ERROR:
        draft.loading = false;
        draft.error = action.payload.message;
        break;

      case ORG_SETTINGS_UPDATE_SUCCESS:
        draft.loading = false;
        draft.success = true;
        draft.error = '';
        break;

      case ORG_SETTINGS_RESET_STATE:
        draft.loading = false;
        draft.error = '';
        draft.success = false;
        break;

      default:
        return state;
    }
  });
};
