import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import * as actions from './actions';
import { getCurrentOrganization } from '../organizations/selectors';

function* processRouteStations(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.processStations,
    {
      organizationId: currentOrganization.id,
    },
    payload.data
  );

  if (response.ok) {
    const { data } = response;

    yield put(actions.onProcessSuccess(data));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(actions.onProcessError(message));
  }
}

export function* watchProcessRouteStations(api) {
  yield takeEvery(
    actions.ROUTE_STATIONS_PROCESS_REQUEST,
    processRouteStations,
    api
  );
}

export default function* rootSaga(api) {
  yield all([fork(watchProcessRouteStations, api)]);
}
