import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  USER_PERMISSION_REQUEST,
  userPermissionSuccess,
  userPermissionError,
} from './actions';

function* userPermissionsRequest(api, { payload }) {
  const response = yield call(api.getUserPermissionByUserId, payload.userId);

  if (response.ok) {
    const { data } = response;
    yield put(userPermissionSuccess({ data }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(userPermissionError(message));
  }
}

export function* watchUserPermissionsRequest(api) {
  yield takeEvery(USER_PERMISSION_REQUEST, userPermissionsRequest, api);
}

export default function* rootSaga(api) {
  yield all([fork(watchUserPermissionsRequest, api)]);
}
