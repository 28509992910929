export const ACTIONS = {
  GET_ORGANIZATIONS: 'SUPER_ADMIN_GET_ORGANIZATIONS',
  ON_SUCCESS: 'SUPER_ADMIN_SUCCESS',
  ON_ERROR: 'SUPER_ADMIN_ERROR',
  SELECT_ORGANIZATION: 'SUPER_ADMIN_SELECT_ORGANIZATION',
  DESELECT_ORGANIZATION: 'SUPER_ADMIN_DESELECT_ORGANIZATION',
  CLEAR_ORGANIZATIONS: 'SUPER_ADMIN_CLEAR_ORGANIZATIONS',
  CREATE_ORGANIZATION: 'SUPER_ADMIN_POST_ORGANIZATION',
  EDIT_ORGANIZATION: 'SUPER_ADMIN_PUT_ORGANIZATION',
  DELETE_ORGANIZATION: 'SUPER_ADMIN_DELETE_ORGANIZATION',
  CREDIT_TRANSFER: 'SUPER_ADMIN_CREDIT_TRANSFER',
  CREDIT_TRANSFER_SUCCESS: 'SUPER_ADMIN_CREDIT_TRANSFER_SUCCESS',
  CREDIT_TRANSFER_ERROR: 'SUPER_ADMIN_CREDIT_TRANSFER_ERROR',
  CREDIT_TRANSFER_RESET: 'SUPER_ADMIN_CREDIT_TRANSFER_RESET',
  ENABLE_ADMIN: 'ENABLE_ADMIN',
  ENABLE_SUPER_ADMIN: 'ENABLE_SUPER_ADMIN',
  GET_ORGANIZATIONS_TREE: 'GET_ORGANIZATIONS_TREE',
  GET_ORGANIZATIONS_TREE_SUCCESS: 'GET_ORGANIZATIONS_TREE_SUCCESS',
  GET_ORGANIZATIONS_TREE_ERROR: 'GET_ORGANIZATIONS_TREE_ERROR',
  SET_EXPENSE_CENTER: 'SET_EXPENSE_CENTER',
  SET_FUEL_MANAGEMENT_MODULE: 'SET_FUEL_MANAGEMENT_MODULE',
};

export const getOrganizationsTree = params => ({
  type: ACTIONS.GET_ORGANIZATIONS_TREE,
  payload: { params },
});

export const getOrganizationsTreeSuccess = organizationsTree => ({
  type: ACTIONS.GET_ORGANIZATIONS_TREE_SUCCESS,
  payload: { organizationsTree },
});

export const getOrganizationsTreeError = message => ({
  type: ACTIONS.GET_ORGANIZATIONS_TREE_ERROR,
  payload: { message },
});

export const getOrganizations = params => ({
  type: ACTIONS.GET_ORGANIZATIONS,
  payload: { params },
});

export const enableAdmin = () => ({
  type: ACTIONS.ENABLE_ADMIN,
});

export const enableSuperAdmin = () => ({
  type: ACTIONS.ENABLE_SUPER_ADMIN,
});

export const onSuccess = payload => ({
  type: ACTIONS.ON_SUCCESS,
  payload,
});

export const onError = message => ({
  type: ACTIONS.ON_ERROR,
  payload: { message },
});

export const onSelectOrganization = currentOrganization => ({
  type: ACTIONS.SELECT_ORGANIZATION,
  payload: { currentOrganization },
});

export const onDeselectOrganization = () => ({
  type: ACTIONS.DESELECT_ORGANIZATION,
});

export const clearOrganizations = () => ({
  type: ACTIONS.CLEAR_ORGANIZATIONS,
});

export const createOrganization = (organization, params) => ({
  type: ACTIONS.CREATE_ORGANIZATION,
  payload: { organization, params },
});

export const editOrganization = (id, organization, params) => ({
  type: ACTIONS.EDIT_ORGANIZATION,
  payload: { id, organization, params },
});

export const deleteOrganization = (organization, params) => ({
  type: ACTIONS.DELETE_ORGANIZATION,
  payload: { organization, params },
});

export const creditTransfer = (id, toOrganization, amount, params) => ({
  type: ACTIONS.CREDIT_TRANSFER,
  payload: { id, data: { toOrganization, amount }, params },
});

export const creditTransferSuccess = () => ({
  type: ACTIONS.CREDIT_TRANSFER_SUCCESS,
});

export const creditTransferError = message => ({
  type: ACTIONS.CREDIT_TRANSFER_ERROR,
  payload: { message },
});

export const creditTransferReset = () => ({
  type: ACTIONS.CREDIT_TRANSFER_RESET,
});

export const setExpenseCenter = data => ({
  type: ACTIONS.SET_EXPENSE_CENTER,
  payload: data,
});

export const setFuelManagementModule = data => ({
  type: ACTIONS.SET_FUEL_MANAGEMENT_MODULE,
  payload: data,
});
