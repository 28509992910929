export const EXPENSE_CENTER_USER = {
  DISTRIBUTION: {
    REQUEST: 'EXPENSE_CENTER_USER_BALANCE_DISTRIBUTION_REQUEST',
    SUCCESS: 'EXPENSE_CENTER_USER_BALANCE_DISTRIBUTION_SUCCESS',
    ERROR: 'EXPENSE_CENTER_USER_BALANCE_DISTRIBUTION_ERROR',
  },
  UPDATE: {
    REQUEST: 'EXPENSE_CENTER_USER_UPDATE_BALANCE_REQUEST',
    SUCCESS: 'EXPENSE_CENTER_USER_UPDATE_BALANCE_SUCCESS',
    ERROR: 'EXPENSE_CENTER_USER_UPDATE_BALANCE_ERROR',
  },
  COLLECT_CREDIT: {
    REQUEST: 'EXPENSE_CENTER_USER_COLLECT_CREDIT_REQUEST',
    SUCCESS: 'EXPENSE_CENTER_USER_COLLECT_CREDIT_SUCCESS',
    ERROR: 'EXPENSE_CENTER_USER_COLLECT_CREDIT_ERROR',
  },
  TOGGLE_SHARE: 'EXPENSE_CENTER_USER_TOGGLE_SHARE_BALANCE',
  TOGGLE_ANYWHERE_REFUELING: 'EXPENSE_CENTER_TOGGLE_ANYWHERE_REFUELING',
};

export const expenseCenterUserBalanceRequest = params => ({
  type: EXPENSE_CENTER_USER.DISTRIBUTION.REQUEST,
  payload: { params },
});

export const expenseCenterUserBalanceSuccess = data => ({
  type: EXPENSE_CENTER_USER.DISTRIBUTION.SUCCESS,
  payload: { data },
});

export const expenseCenterUserBalanceError = error => ({
  type: EXPENSE_CENTER_USER.DISTRIBUTION.ERROR,
  payload: { error },
});

export const expenseCenterUpdateUserBalance = data => ({
  type: EXPENSE_CENTER_USER.UPDATE.REQUEST,
  payload: { data },
});

export const expenseCenterUpdateUserBalanceSuccess = () => ({
  type: EXPENSE_CENTER_USER.UPDATE.SUCCESS,
});

export const expenseCenterUpdateUserBalanceError = error => ({
  type: EXPENSE_CENTER_USER.UPDATE.ERROR,
  payload: { error },
});

export const expenseCenterShareUserBalance = (
  id,
  status,
  wallet,
  expenseCenter,
  allowAnywhereRefueling
) => ({
  type: EXPENSE_CENTER_USER.TOGGLE_SHARE,
  payload: { id, status, wallet, expenseCenter, allowAnywhereRefueling },
});

export const expenseCenterCollectUserCredit = (id, wallets) => ({
  type: EXPENSE_CENTER_USER.COLLECT_CREDIT.REQUEST,
  payload: { id, wallets },
});

export const expenseCenterCollectUserCreditSuccess = () => ({
  type: EXPENSE_CENTER_USER.COLLECT_CREDIT.SUCCESS,
});

export const expenseCenterCollectUserCreditError = error => ({
  type: EXPENSE_CENTER_USER.COLLECT_CREDIT.ERROR,
  payload: { error },
});

export const expenseCenterAllowAnywhereRefueling = (userId, data) => ({
  type: EXPENSE_CENTER_USER.TOGGLE_ANYWHERE_REFUELING,
  payload: { userId, data },
});
