import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { getCurrentOrganization } from 'redux/organizations/selectors';
import { APIParams } from 'helpers/Utils';
import * as actions from './actions';
import * as selectors from './selectors';

function* handleWalletsListRequest(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);

  const response = yield call(
    api.listWallets,
    APIParams({ ...payload.params, organizationId: currentOrganization.id })
  );

  if (response.ok) {
    const { data: list, balanceSharingType } = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(
      actions.walletsListSuccess({ list, balanceSharingType, pages, items })
    );
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(actions.walletsListError(message));
  }
}

function* handleCreateWallet(api, { payload }) {
  const list = yield select(selectors.getWallets);

  const response = yield call(api.createWallet, payload.data);

  if (response.ok) {
    const updatedList = list.map(item => {
      const { user, vehicle } = response.data;
      if (item._id === (user || vehicle)) {
        return {
          ...item,
          fuelManagementWallets: [...item.fuelManagementWallets, response.data],
        };
      }
      return item;
    });

    yield put(actions.createWalletSuccess({ list: updatedList }));
  } else {
    const message = 'Ocorreu um erro ao habilitar carteira';
    yield put(actions.createWalletError(message));
  }
}

function* handleUpdateWallet(api, { payload }) {
  const list = yield select(selectors.getWallets);

  const response = yield call(api.updateWallet, payload.id, payload.data);

  if (response.ok) {
    const updatedList = yield list.map(item => {
      const { user, vehicle } = response.data;

      if (item._id === (user || vehicle)) {
        const fuelManagementWallets = item.fuelManagementWallets.map(wallet =>
          wallet._id === payload.id ? response.data : wallet
        );

        return {
          ...item,
          balance: fuelManagementWallets.reduce(
            (total, wallet) => total + wallet.balance,
            0
          ),
          fuelManagementWallets,
        };
      }
      return item;
    });

    yield put(actions.updateWalletSuccess({ list: updatedList }));
  } else {
    const message = 'Ocorreu um erro ao atualizar carteira';
    yield put(actions.updateWalletError(message));
  }
}

function* handleUpdateWalletLimit(api, { payload }) {
  const response = yield call(api.updateWalletLimit, payload.data);

  if (response.ok) {
    yield put(actions.updateWalletLimitSuccess());
  } else {
    const message = 'Ocorreu um erro ao atualizar carteira';
    yield put(actions.updateWalletLimitError(message));
  }
}

function* handleUpdateMultipleWallets(api, { payload }) {
  const list = yield select(selectors.getWallets);

  const response = yield call(api.updateMultipleWallets, payload.data);

  if (response.ok) {
    const updatedList = yield list.map(item => {
      if (
        response.data.some(
          ({ user, vehicle }) => item._id === (user || vehicle)
        )
      ) {
        const fuelManagementWallets =
          item.fuelManagementWallets.length !== response.data.length
            ? [
              ...item.fuelManagementWallets.filter(wallet =>
                response.data.every(
                  updatedWallet => wallet._id !== updatedWallet._id
                )
              ),
              ...response.data,
            ]
            : response.data;

        return {
          ...item,
          balance: fuelManagementWallets.reduce(
            (total, wallet) => total + wallet.balance,
            0
          ),
          fuelManagementWallets,
        };
      }
      return item;
    });

    yield put(actions.updateMultipleWalletsSuccess({ list: updatedList }));
  } else {
    const message = 'Ocorreu um erro ao atualizar carteiras';
    yield put(actions.updateMultipleWalletsError(message));
  }
}

function* handleShareBalance(api, { payload }) {
  const list = yield select(selectors.getWallets);

  const response = yield call(api.shareBalance, payload.data);

  if (response.ok) {
    const updatedList = yield list.map(item => {
      if (
        response.data.some(
          ({ user, vehicle }) => item._id === (user || vehicle)
        )
      ) {
        const fuelManagementWallets =
          item.fuelManagementWallets.length !== response.data.length
            ? [
              ...item.fuelManagementWallets.filter(wallet =>
                response.data.every(
                  updatedWallet => wallet._id !== updatedWallet._id
                )
              ),
              ...response.data,
            ]
            : response.data;

        return {
          ...item,
          balance: fuelManagementWallets.reduce(
            (total, wallet) => total + wallet.balance,
            0
          ),
          sharedBalance: fuelManagementWallets.some(
            ({ sharedBalance }) => sharedBalance
          ),
          fuelManagementWallets,
          limit: fuelManagementWallets.reduce(
            (total, wallet) => total + wallet.limit,
            0
          ),
        };
      }
      return item;
    });

    yield put(actions.shareBalanceSuccess({ list: updatedList }));
  } else {
    const message = 'Ocorreu um erro ao atualizar carteiras';
    yield put(actions.shareBalanceError(message));
  }
}

function* watchWalletsRequest(api) {
  yield takeEvery(
    actions.FUEL_MANAGEMENT_WALLETS_LIST_REQUEST,
    handleWalletsListRequest,
    api
  );
}

function* watchCreateWallet(api) {
  yield takeEvery(
    actions.FUEL_MANAGEMENT_CREATE_WALLET,
    handleCreateWallet,
    api
  );
}

function* watchUpdateWallet(api) {
  yield takeEvery(
    actions.FUEL_MANAGEMENT_UPDATE_WALLET,
    handleUpdateWallet,
    api
  );
}

function* watchUpdateWalletLimit(api) {
  yield takeEvery(
    actions.FUEL_MANAGEMENT_UPDATE_WALLET_LIMIT,
    handleUpdateWalletLimit,
    api
  );
}

function* watchUpdateMultipleWallets(api) {
  yield takeEvery(
    actions.FUEL_MANAGEMENT_UPDATE_MULTIPLE_WALLETS,
    handleUpdateMultipleWallets,
    api
  );
}

function* watchShareBalance(api) {
  yield takeEvery(
    actions.FUEL_MANAGEMENT_SHARE_BALANCE,
    handleShareBalance,
    api
  );
}

export default function* rootSaga(api) {
  yield all([
    fork(watchWalletsRequest, api),
    fork(watchCreateWallet, api),
    fork(watchUpdateWallet, api),
    fork(watchUpdateWalletLimit, api),
    fork(watchUpdateMultipleWallets, api),
    fork(watchShareBalance, api),
  ]);
}
