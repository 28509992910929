import produce from 'immer';

import {
  SUGGESTION_REQUEST,
  SUGGESTION_SUCCESS,
  SUGGESTION_ERROR,
  PLACE_REQUEST,
  PLACE_SUCCESS,
  PLACE_ERROR,
} from './actions';

const INIT_STATE = {
  list: [],
  place: null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case SUGGESTION_REQUEST:
        draft.loading = true;
        draft.error = '';
        break;

      case SUGGESTION_SUCCESS:
        draft.loading = false;
        draft.list = action.payload.data;
        draft.error = '';
        break;

      case SUGGESTION_ERROR:
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;

      case PLACE_REQUEST:
        draft.loading = true;
        draft.error = '';
        break;

      case PLACE_SUCCESS:
        draft.loading = false;
        draft.place = action.payload.data;
        draft.error = '';
        break;

      case PLACE_ERROR:
        draft.loading = false;
        draft.error = action.payload.message;
        break;

      default:
        return state;
    }
  });
};
