import produce from 'immer';

import {
  ROUTE_STATIONS_PROCESS_ERROR,
  ROUTE_STATIONS_PROCESS_REQUEST,
  ROUTE_STATIONS_PROCESS_SUCCESS,
} from './actions';

const INIT_STATE = {
  gasStations: [],
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ROUTE_STATIONS_PROCESS_REQUEST:
        draft.loading = true;
        draft.error = '';
        break;

      case ROUTE_STATIONS_PROCESS_SUCCESS:
        draft.loading = false;
        draft.gasStations = action.payload.data;
        draft.error = '';
        break;

      case ROUTE_STATIONS_PROCESS_ERROR:
        draft.loading = false;
        draft.gasStations = [];
        draft.error = action.payload.message;
        break;

      default:
        return state;
    }
  });
};
