import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  setMenu,
  setExpenseCenter,
  setFuelManagementModule,
} from 'redux/actions';
import { EXPENSE_CENTER_PLAN } from 'constants/common';
import {
  SUBSCRIPTION_REQUEST,
  subscriptionSuccess,
  subscriptionError,
} from './actions';

function* subscriptionRequest(api) {
  const response = yield call(api.subscription.get);

  if (response.ok) {
    const { _subscription, menus, hasFuelManagementModule } = response.data;

    if (_subscription._plan === EXPENSE_CENTER_PLAN) {
      yield put(setExpenseCenter(true));
    } else {
      yield put(setExpenseCenter(false));
    }

    if (hasFuelManagementModule) {
      yield put(setFuelManagementModule(true));
    } else {
      yield put(setFuelManagementModule(false));
    }

    yield put(subscriptionSuccess(_subscription));
    yield put(setMenu(menus));
  } else {
    const message = 'Não foi possível carregar a assinatura';
    yield put(subscriptionError(message));
    yield put(setMenu([]));
  }
}

export function* watchSubscriptionRequest(api) {
  yield takeEvery(SUBSCRIPTION_REQUEST, subscriptionRequest, api);
}

export default function* rootSaga(api) {
  yield all([fork(watchSubscriptionRequest, api)]);
}
