import produce from 'immer';

import {
  MENU_SET_CLASSNAMES,
  MENU_CONTAINER_ADD_CLASSNAME,
  MENU_CLICK_MOBILE_MENU,
  MENU_CHANGE_DEFAULT_CLASSES,
  MENU_CHANGE_HAS_SUB_ITEM_STATUS,
  SET_MENU,
} from '../actions';

import {
  defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
} from '../../constants/defaultValues';

const INIT_STATE = {
  containerClassnames: defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
  menuClickCount: 0,
  selectedMenuHasSubItems: defaultMenuType === 'menu-default', // if you use menu-sub-hidden as default menu type, set value of this variable to false
  menus: [],
  loading: false,
  error: '',
  success: false,
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case MENU_CHANGE_HAS_SUB_ITEM_STATUS:
        draft.selectedMenuHasSubItems = action.payload;
        break;

      case MENU_SET_CLASSNAMES:
        draft.containerClassnames = action.payload.containerClassnames;
        draft.menuClickCount = action.payload.menuClickCount;
        break;

      case MENU_CLICK_MOBILE_MENU:
        draft.containerClassnames = action.payload.containerClassnames;
        draft.menuClickCount = action.payload.menuClickCount;
        break;

      case MENU_CONTAINER_ADD_CLASSNAME:
        draft.containerClassnames = action.payload;
        break;

      case MENU_CHANGE_DEFAULT_CLASSES:
        draft.containerClassnames = action.payload;
        break;

      case SET_MENU:
        draft.menus = action.payload;
        break;

      default:
        return state;
    }
  });
};
