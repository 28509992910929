export const ORG_SETTINGS_LIST_REQUEST = 'ORG_SETTINGS_LIST_REQUEST';
export const ORG_SETTINGS_LIST_SUCCESS = 'ORG_SETTINGS_LIST_SUCCESS';
export const ORG_SETTINGS_LIST_ERROR = 'ORG_SETTINGS_LIST_ERROR';

export const ORG_SETTINGS_UPDATE_REQUEST = 'ORG_SETTINGS_UPDATE_REQUEST';
export const ORG_SETTINGS_UPDATE_ERROR = 'ORG_SETTINGS_UPDATE_ERROR';
export const ORG_SETTINGS_UPDATE_SUCCESS = 'ORG_SETTINGS_UPDATE_SUCCESS';
export const ORG_SETTINGS_RESET_STATE = 'ORG_SETTINGS_RESET_STATE';

export const listOrgSettingsRequest = params => ({
  type: ORG_SETTINGS_LIST_REQUEST,
  payload: { params },
});

export const listOrgSettingsSuccess = payload => ({
  type: ORG_SETTINGS_LIST_SUCCESS,
  payload,
});

export const listOrgSettingsError = message => ({
  type: ORG_SETTINGS_LIST_ERROR,
  payload: { message },
});

export const updateOrgSettingsRequest = (body, organizationId) => ({
  type: ORG_SETTINGS_UPDATE_REQUEST,
  payload: { body, organizationId },
});

export const updateOrgSettingsError = message => ({
  type: ORG_SETTINGS_UPDATE_ERROR,
  payload: { message },
});

export const updateOrgSettingsSuccess = payload => ({
  type: ORG_SETTINGS_UPDATE_SUCCESS,
  payload,
});

export const resetOrgSettingsState = () => ({
  type: ORG_SETTINGS_RESET_STATE,
});
