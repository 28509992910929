import produce from 'immer';

import {
  RECIPIENT_LIST_REQUEST,
  RECIPIENT_LIST_SUCCESS,
  RECIPIENT_LIST_ERROR,
  RECIPIENT_REGISTER_REQUEST,
  RECIPIENT_REGISTER_SUCCESS,
  RECIPIENT_REGISTER_ERROR,
  RECIPIENT_DELETE_REQUEST,
  RECIPIENT_DELETE_ERROR,
  RECIPIENT_EDIT_REQUEST,
  RECIPIENT_EDIT_ERROR,
  RECIPIENT_UNDELETE_REQUEST,
  RECIPIENT_UNDELETE_ERROR,
  RECIPIENT_TURN_DRIVER_REQUEST,
  RECIPIENT_TURN_DRIVER_ERROR,
  RECIPIENT_TURN_DRIVER_SUCCESS,
  RECIPIENT_TURN_DRIVER_RESET,
  RECIPIENT_PASSWORD_RECOVERY_REQUEST,
  RECIPIENT_PASSWORD_RECOVERY_ERROR,
} from './actions';

const INIT_STATE = {
  list: [],
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
  success: false,
  turnDriver: {
    loading: false,
    error: '',
    success: false,
  },
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case RECIPIENT_LIST_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case RECIPIENT_LIST_SUCCESS: {
        draft.loading = false;
        draft.list = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      }

      case RECIPIENT_LIST_ERROR: {
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      }

      case RECIPIENT_REGISTER_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case RECIPIENT_REGISTER_SUCCESS: {
        draft.loading = false;
        draft.error = '';
        break;
      }

      case RECIPIENT_REGISTER_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }

      case RECIPIENT_DELETE_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case RECIPIENT_DELETE_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }

      case RECIPIENT_EDIT_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case RECIPIENT_EDIT_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }

      case RECIPIENT_UNDELETE_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case RECIPIENT_UNDELETE_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }

      case RECIPIENT_TURN_DRIVER_REQUEST: {
        draft.turnDriver.loading = true;
        draft.turnDriver.error = '';
        break;
      }

      case RECIPIENT_TURN_DRIVER_ERROR: {
        draft.turnDriver.loading = false;
        draft.turnDriver.error = action.payload.message;
        draft.turnDriver.success = false;
        break;
      }

      case RECIPIENT_TURN_DRIVER_SUCCESS: {
        draft.turnDriver.loading = false;
        draft.turnDriver.error = '';
        draft.turnDriver.success = true;
        break;
      }

      case RECIPIENT_TURN_DRIVER_RESET: {
        draft.turnDriver.loading = false;
        draft.turnDriver.error = '';
        draft.turnDriver.success = false;
        break;
      }

      case RECIPIENT_PASSWORD_RECOVERY_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case RECIPIENT_PASSWORD_RECOVERY_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }

      default:
        return state;
    }
  });
};
