import produce from 'immer';

import {
  CONTAINER_LIST_REQUEST,
  CONTAINER_LIST_SUCCESS,
  CONTAINER_LIST_ERROR,
  CONTAINER_REGISTER_REQUEST,
  CONTAINER_REGISTER_SUCCESS,
  CONTAINER_REGISTER_ERROR,
  CONTAINER_EDIT_REQUEST,
  CONTAINER_EDIT_ERROR,
  CONTAINER_DELETE_REQUEST,
  CONTAINER_DELETE_ERROR,
  CONTAINER_UNDELETE_REQUEST,
  CONTAINER_UNDELETE_ERROR,
} from '../actions';

const INIT_STATE = {
  list: [],
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
  success: false,
};

export default (state = INIT_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CONTAINER_LIST_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case CONTAINER_LIST_SUCCESS: {
        draft.loading = false;
        draft.list = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      }
      case CONTAINER_LIST_ERROR: {
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      }
      case CONTAINER_REGISTER_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case CONTAINER_REGISTER_SUCCESS: {
        draft.loading = false;
        draft.error = '';
        break;
      }
      case CONTAINER_REGISTER_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }
      case CONTAINER_DELETE_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case CONTAINER_DELETE_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }
      case CONTAINER_EDIT_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case CONTAINER_EDIT_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }
      case CONTAINER_UNDELETE_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case CONTAINER_UNDELETE_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }
      default:
        return state;
    }
  });
