import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/v2/admin/organization/transaction${objectToURLParams(parameters)}`);

export const listTankTransactions = parameters =>
  api.get(
    `/admin/organization/tank/transaction${objectToURLParams(parameters)}`
  );
