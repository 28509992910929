export const DEPOSIT_LIST_REQUEST = 'DEPOSIT_LIST_REQUEST';
export const DEPOSIT_LIST_SUCCESS = 'DEPOSIT_LIST_SUCCESS';
export const DEPOSIT_LIST_ERROR = 'DEPOSIT_LIST_ERROR';
export const DEPOSIT_ATTACHMENTS_REQUEST = 'DEPOSIT_ATTACHMENTS_REQUEST';
export const DEPOSIT_ATTACHMENT_SUCCESS = 'DEPOSIT_ATTACHMENT_SUCCESS';
export const DEPOSIT_ATTACHMENT_ERROR = 'DEPOSIT_ATTACHMENT_ERROR';

export const listDeposits = params => ({
  type: DEPOSIT_LIST_REQUEST,
  payload: { params },
});

export const onListDepositsSuccess = payload => ({
  type: DEPOSIT_LIST_SUCCESS,
  payload,
});

export const onListDepositsError = message => ({
  type: DEPOSIT_LIST_ERROR,
  payload: { message },
});

export const listDepositAttachments = params => ({
  type: DEPOSIT_ATTACHMENTS_REQUEST,
  payload: { params },
});

export const onDepositAttachmentSuccess = payload => ({
  type: DEPOSIT_ATTACHMENT_SUCCESS,
  payload,
});

export const onDepositAttachmentError = message => ({
  type: DEPOSIT_ATTACHMENT_ERROR,
  payload: { message },
});
