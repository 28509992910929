export const FUEL_MANAGEMENT_LIST_REQUEST = 'FUEL_MANAGEMENT_LIST_REQUEST';
export const FUEL_MANAGEMENT_LIST_SUCCESS = 'FUEL_MANAGEMENT_LIST_SUCCESS';
export const FUEL_MANAGEMENT_LIST_ERROR = 'FUEL_MANAGEMENT_LIST_ERROR';
export const FUEL_MANAGEMENT_REFUEL_REQUEST = 'FUEL_MANAGEMENT_REFUEL_REQUEST';
export const FUEL_MANAGEMENT_REFUEL_SUCCESS = 'FUEL_MANAGEMENT_REFUEL_SUCCESS';
export const FUEL_MANAGEMENT_REFUEL_ERROR = 'FUEL_MANAGEMENT_REFUEL_ERROR';
export const FUEL_MANAGEMENT_REFUEL_RESET = 'FUEL_MANAGEMENT_REFUEL_RESET';

export const listFuelManagementRequest = params => ({
  type: FUEL_MANAGEMENT_LIST_REQUEST,
  payload: { params },
});

export const listFuelManagementSuccess = payload => ({
  type: FUEL_MANAGEMENT_LIST_SUCCESS,
  payload,
});

export const listFuelManagementError = message => ({
  type: FUEL_MANAGEMENT_LIST_ERROR,
  payload: { message },
});

export const refuelFuelManagementRequest = (id, payload, params) => ({
  type: FUEL_MANAGEMENT_REFUEL_REQUEST,
  payload: { id, payload, params },
});

export const refuelFuelManagementSuccess = payload => ({
  type: FUEL_MANAGEMENT_REFUEL_SUCCESS,
  payload,
});

export const refuelFuelManagementError = message => ({
  type: FUEL_MANAGEMENT_REFUEL_ERROR,
  payload: { message },
});

export const refuelFuelManagementReset = () => ({
  type: FUEL_MANAGEMENT_REFUEL_RESET,
});
