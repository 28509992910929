import produce from 'immer';

import {
  SUBSCRIPTION_REQUEST,
  SUBSCRIPTION_SUCCESS,
  SUBSCRIPTION_ERROR,
} from './actions';

const INIT_STATE = {
  loading: false,
  error: '',
  success: false,
  subscription: {},
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case SUBSCRIPTION_REQUEST:
        draft.loading = true;
        draft.error = '';
        draft.success = false;
        break;

      case SUBSCRIPTION_SUCCESS:
        draft.subscription = action.payload;
        draft.loading = false;
        draft.error = '';
        draft.success = true;
        break;

      case SUBSCRIPTION_ERROR:
        draft.loading = false;
        draft.error = action.payload.message;
        draft.success = false;
        draft.subscription = {};
        break;

      default:
        return state;
    }
  });
};
