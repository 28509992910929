export const TRANSACTION_LIST_REQUEST = 'TRANSACTION_LIST_REQUEST';
export const TRANSACTION_LIST_SUCCESS = 'TRANSACTION_LIST_SUCCESS';
export const TRANSACTION_LIST_ERROR = 'TRANSACTION_LIST_ERROR';

export const listTransaction = params => ({
  type: TRANSACTION_LIST_REQUEST,
  payload: { params },
});

export const listTransactionSuccess = payload => ({
  type: TRANSACTION_LIST_SUCCESS,
  payload,
});

export const listTransactionError = message => ({
  type: TRANSACTION_LIST_ERROR,
  payload: { message },
});
