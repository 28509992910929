import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import {
  RECIPIENT_LIST_REQUEST,
  RECIPIENT_REGISTER_REQUEST,
  RECIPIENT_DELETE_REQUEST,
  RECIPIENT_EDIT_REQUEST,
  RECIPIENT_UNDELETE_REQUEST,
  RECIPIENT_TURN_DRIVER_REQUEST,
  RECIPIENT_PASSWORD_RECOVERY_REQUEST,
  listRecipientRequest,
  listRecipientSuccess,
  listRecipientError,
  registerRecipientSuccess,
  registerRecipientError,
  editRecipientError,
  deleteRecipientError,
  undeleteRecipientError,
  turnDriverError,
  recipientPasswordRecoveryError,
  turnDriverSuccess,
} from './actions';
import { getCurrentOrganization } from '../organizations/selectors';

function* listRecipient(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const recipients = yield call(
    api.listRecipient,
    APIParams({ ...payload.params, organizationId: currentOrganization.id })
  );

  if (recipients.ok) {
    const list = recipients.data.docs;
    const { current: currentPage, total: totalPages } = recipients.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = recipients.data.items;

    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listRecipientSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(listRecipientError(message));
  }
}

function* registerRecipient(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const apiRequest = yield call(
    api.registerRecipient,
    payload.body,
    currentOrganization.id
  );

  if (apiRequest.ok) {
    yield put(registerRecipientSuccess());
  } else {
    yield put(registerRecipientError(apiRequest.data));
  }
  yield put(listRecipientRequest(payload.params));
}

function* deleteRecipient(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.deleteRecipient,
    payload.id,
    currentOrganization.id
  );

  if (response.ok) {
    yield put(listRecipientRequest(payload.params));
  } else {
    const message = 'Não foi possível remover o beneficiário';
    yield put(deleteRecipientError(message));
  }
}

function* editRecipient(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.editRecipient,
    payload,
    currentOrganization.id
  );

  if (response.ok) {
    yield put(listRecipientRequest(payload.params));
  } else {
    const message = 'Não foi possível editar o beneficiário';
    yield put(editRecipientError(message));
  }
}

function* undeleteRecipient(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.undeleteRecipient,
    payload.id,
    currentOrganization.id
  );

  if (response.ok) {
    yield put(listRecipientRequest(payload.params));
  } else {
    const message = 'Não foi possível editar o beneficiário';
    yield put(undeleteRecipientError(message));
  }
}

function* turnDriver(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.turnDriver,
    payload.id,
    currentOrganization.id
  );

  if (response.ok) {
    yield put(turnDriverSuccess());
    yield put(listRecipientRequest(payload.params));
  } else {
    const message = 'Não foi possível tornar o beneficiário em condutor';
    yield put(turnDriverError(message));
  }
}

function* passwordRecovery(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.passwordRecovery,
    payload,
    currentOrganization.id
  );

  if (response.ok) {
    yield put(listRecipientRequest(payload.params));
  } else {
    const message = 'Não foi possível alterar a senha do beneficiário';
    yield put(recipientPasswordRecoveryError(message));
  }
}

export function* watchListRecipientRequest(api) {
  yield takeEvery(RECIPIENT_LIST_REQUEST, listRecipient, api);
}

export function* watchRegisterRecipientRequest(api) {
  yield takeEvery(RECIPIENT_REGISTER_REQUEST, registerRecipient, api);
}

function* watchDeleteRecipientRequest(api) {
  yield takeEvery(RECIPIENT_DELETE_REQUEST, deleteRecipient, api);
}

function* watchEditRecipientRequest(api) {
  yield takeEvery(RECIPIENT_EDIT_REQUEST, editRecipient, api);
}

function* watchUndeleteRecipientRequest(api) {
  yield takeEvery(RECIPIENT_UNDELETE_REQUEST, undeleteRecipient, api);
}

function* watchTurnDriverRequest(api) {
  yield takeEvery(RECIPIENT_TURN_DRIVER_REQUEST, turnDriver, api);
}

function* watchPasswordRecoveryRequest(api) {
  yield takeEvery(RECIPIENT_PASSWORD_RECOVERY_REQUEST, passwordRecovery, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListRecipientRequest, api),
    fork(watchRegisterRecipientRequest, api),
    fork(watchDeleteRecipientRequest, api),
    fork(watchEditRecipientRequest, api),
    fork(watchUndeleteRecipientRequest, api),
    fork(watchTurnDriverRequest, api),
    fork(watchPasswordRecoveryRequest, api),
  ]);
}
