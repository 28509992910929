import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import { getCurrentOrganization } from '../../organizations/selectors';
import * as selectors from '../selectors';
import * as actions from './actions';
import * as types from '../types';

function* handleVehicleDistributionRequest(api, { payload }) {
  try {
    const currentOrganization = yield select(getCurrentOrganization);
    const response = yield call(
      api.balanceDistribution,
      APIParams({ ...payload.params, organizationId: currentOrganization.id })
    );

    if (!response) {
      throw new Error('request error');
    }

    const data = {
      balance: response.data.balance,
      vehicles: response.data.vehicles.map(vehicle => ({
        sharedBalance: vehicle.wallets.some(wallet => wallet.sharedBalance)
          ? 1
          : 0,
        balance: vehicle.wallets.reduce(
          (total, wallet) => total + wallet.balance,
          0
        ),
        id: vehicle._id,
        licensePlate: vehicle.licensePlate,
        make: vehicle.make,
        model: vehicle.model,
        wallet: vehicle.wallets[0] && vehicle.wallets[0]._id,
        allowAnywhereRefueling:
          vehicle.wallets[0] && vehicle.wallets[0].allowAnywhereRefueling
            ? 1
            : 0,
      })),
      hasAnywhere: response.data.hasAnywhere,
    };

    yield put(actions.vehicleBalanceSuccess(data));
  } catch (error) {
    yield put(actions.vehicleBalanceError(error.message));
  }
}

function* handleShareVehicleBalanceToggle(api, { payload }) {
  try {
    const currentOrganization = yield select(getCurrentOrganization);

    if (payload.status) {
      const response = yield call(api.unshareVehicleBalance, {
        organizationId: currentOrganization.id,
        id: payload.id,
      });

      if (!response) {
        throw new Error('request error');
      }
    }

    const { vehicles, balance, hasAnywhere } = yield select(
      selectors.getVehicleBalance
    );
    let newBalanceValue = balance;

    const updatedVehicles = vehicles.map(vehicle => {
      if (vehicle.id === payload.id) {
        if (vehicle.balance) {
          newBalanceValue += vehicle.balance;
          return {
            ...vehicle,
            sharedBalance: +payload.status,
            balance: 0,
          };
        }

        return {
          ...vehicle,
          sharedBalance: +payload.status,
        };
      }

      return vehicle;
    });

    const data = {
      balance: newBalanceValue,
      vehicles: updatedVehicles,
      hasAnywhere,
    };

    yield put(actions.vehicleBalanceSuccess(data));
  } catch (error) {
    yield put(actions.vehicleBalanceError(error.message));
  }
}

function* handleUpdateVehicleBalanceRequest(api, { payload }) {
  try {
    const currentOrganization = yield select(getCurrentOrganization);
    const { vehicles, balance, hasAnywhere } = yield select(
      selectors.getVehicleBalance
    );

    const totalToAdd = payload.data.reduce(
      (accumulator, currentValue) => accumulator.share + currentValue.share,
      { share: 0 }
    );

    if (totalToAdd > balance) {
      throw new Error('O saldo total ultrapassa o limite disponível');
    }

    const request = yield call(api.updateVehicleSharedBalance, {
      data: payload.data,
      organizationId: currentOrganization.id,
    });

    if (!request.ok) {
      throw new Error('update shared balance');
    }

    yield put(
      actions.vehicleBalanceSuccess({
        balance: balance - totalToAdd,
        vehicles,
        hasAnywhere,
      })
    );

    yield put(actions.updateVehicleBalanceSuccess());
  } catch (error) {
    yield put(actions.updateVehicleBalanceError(error.message));
  }
}

function* handleCollectVehicleCreditRequest(api, { payload }) {
  try {
    const currentOrganization = yield select(getCurrentOrganization);
    const { vehicles, balance, hasAnywhere } = yield select(
      selectors.getVehicleBalance
    );

    let collectedBalance;

    const dataWithUpdates = vehicles.map(d => {
      if (d.id !== payload.id) {
        return d;
      }

      collectedBalance = d.balance;
      return {
        ...d,
        balance: 0,
      };
    });

    const request = yield call(api.reapVehicleBalance, {
      id: payload.id,
      organizationId: currentOrganization.id,
    });

    if (!request.ok) {
      throw new Error('update shared balance');
    }

    yield put(
      actions.vehicleBalanceSuccess({
        balance: balance + collectedBalance,
        vehicles: dataWithUpdates,
        hasAnywhere,
      })
    );

    yield put(actions.collectVehicleCreditSuccess());
  } catch (error) {
    yield put(actions.collectVehicleCreditError(error.message));
  }
}

function* handleAllowAnywhereVehicleRefueling(api, { payload }) {
  try {
    const currentOrganization = yield select(getCurrentOrganization);

    const response = yield call(api.allowAnywhereRefueling, {
      organizationId: currentOrganization.id,
      vehicleId: payload.vehicleId,
      data: payload.data,
    });

    if (!response) {
      throw new Error('request error');
    }

    if (response.data?.error) {
      throw new Error(response.data?.message);
    }

    const { vehicles, balance, hasAnywhere } = yield select(
      selectors.getVehicleBalance
    );

    const updatedVehicles = vehicles.map(vehicle => {
      if (vehicle.id === payload.vehicleId) {
        return {
          ...vehicle,
          allowAnywhereRefueling: +payload.data.allowAnywhereRefueling,
        };
      }

      return vehicle;
    });

    const data = {
      balance,
      vehicles: updatedVehicles,
      hasAnywhere,
    };

    yield put(actions.vehicleBalanceSuccess(data));
  } catch (error) {
    yield put(actions.vehicleBalanceError(error.message));
  }
}

function* watchVehicleDistributionRequest(api) {
  yield takeEvery(
    types.VEHICLE_DISTRIBUTION.REQUEST,
    handleVehicleDistributionRequest,
    api
  );
}

function* watchShareVehicleBalanceFromVehicle(api) {
  yield takeEvery(
    types.VEHICLE_TOGGLE_SHARE,
    handleShareVehicleBalanceToggle,
    api
  );
}

function* watchUpdateVehicleBalanceRequest(api) {
  yield takeEvery(
    types.VEHICLE_UPDATE.REQUEST,
    handleUpdateVehicleBalanceRequest,
    api
  );
}

function* watchCollectVehicleCreditRequest(api) {
  yield takeEvery(
    types.VEHICLE_COLLECT_CREDIT.REQUEST,
    handleCollectVehicleCreditRequest,
    api
  );
}

function* watchAllowAnywhereRefueling(api) {
  yield takeEvery(
    types.TOGGLE_ANYWHERE_VEHICLE_REFUELING,
    handleAllowAnywhereVehicleRefueling,
    api
  );
}

export default function* rootSaga(api) {
  yield all([
    fork(watchVehicleDistributionRequest, api),
    fork(watchShareVehicleBalanceFromVehicle, api),
    fork(watchUpdateVehicleBalanceRequest, api),
    fork(watchCollectVehicleCreditRequest, api),
    fork(watchAllowAnywhereRefueling, api),
  ]);
}
