export const EXPENSE_CENTER_VEHICLE = {
  DISTRIBUTION: {
    REQUEST: 'EXPENSE_CENTER_VEHICLE_BALANCE_DISTRIBUTION_REQUEST',
    SUCCESS: 'EXPENSE_CENTER_VEHICLE_BALANCE_DISTRIBUTION_SUCCESS',
    ERROR: 'EXPENSE_CENTER_VEHICLE_BALANCE_DISTRIBUTION_ERROR',
  },
  UPDATE: {
    REQUEST: 'EXPENSE_CENTER_VEHICLE_UPDATE_BALANCE_REQUEST',
    SUCCESS: 'EXPENSE_CENTER_VEHICLE_UPDATE_BALANCE_SUCCESS',
    ERROR: 'EXPENSE_CENTER_VEHICLE_UPDATE_BALANCE_ERROR',
  },
  COLLECT_CREDIT: {
    REQUEST: 'EXPENSE_CENTER_VEHICLE_COLLECT_CREDIT_REQUEST',
    SUCCESS: 'EXPENSE_CENTER_VEHICLE_COLLECT_CREDIT_SUCCESS',
    ERROR: 'EXPENSE_CENTER_VEHICLE_COLLECT_CREDIT_ERROR',
  },
  TOGGLE_SHARE: 'EXPENSE_CENTER_VEHICLE_TOGGLE_SHARE_BALANCE',
  TOGGLE_ANYWHERE_REFUELING: 'EXPENSE_CENTER_VEHICLE_TOGGLE_ANYWHERE_REFUELING',
};

export const expenseCenterVehicleBalanceRequest = params => ({
  type: EXPENSE_CENTER_VEHICLE.DISTRIBUTION.REQUEST,
  payload: { params },
});

export const expenseCenterVehicleBalanceSuccess = data => ({
  type: EXPENSE_CENTER_VEHICLE.DISTRIBUTION.SUCCESS,
  payload: { data },
});

export const expenseCenterVehicleBalanceError = error => ({
  type: EXPENSE_CENTER_VEHICLE.DISTRIBUTION.ERROR,
  payload: { error },
});

export const expenseCenterUpdateVehicleBalance = data => ({
  type: EXPENSE_CENTER_VEHICLE.UPDATE.REQUEST,
  payload: { data },
});

export const expenseCenterUpdateVehicleBalanceSuccess = () => ({
  type: EXPENSE_CENTER_VEHICLE.UPDATE.SUCCESS,
});

export const expenseCenterUpdateVehicleBalanceError = error => ({
  type: EXPENSE_CENTER_VEHICLE.UPDATE.ERROR,
  payload: { error },
});

export const expenseCenterShareVehicleBalance = (
  id,
  status,
  wallet,
  expenseCenter,
  allowAnywhereRefueling
) => ({
  type: EXPENSE_CENTER_VEHICLE.TOGGLE_SHARE,
  payload: { id, status, wallet, expenseCenter, allowAnywhereRefueling },
});

export const expenseCenterCollectVehicleCredit = (id, wallets) => ({
  type: EXPENSE_CENTER_VEHICLE.COLLECT_CREDIT.REQUEST,
  payload: { id, wallets },
});

export const expenseCenterCollectVehicleCreditSuccess = () => ({
  type: EXPENSE_CENTER_VEHICLE.COLLECT_CREDIT.SUCCESS,
});

export const expenseCenterCollectVehicleCreditError = error => ({
  type: EXPENSE_CENTER_VEHICLE.COLLECT_CREDIT.ERROR,
  payload: { error },
});

export const expenseCenterAllowAnywhereVehicleRefueling = (
  vehicleId,
  data
) => ({
  type: EXPENSE_CENTER_VEHICLE.TOGGLE_ANYWHERE_REFUELING,
  payload: { vehicleId, data },
});
