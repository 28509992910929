export const DRIVER_LIST_REQUEST = 'DRIVER_LIST_REQUEST';

export const DRIVER_LIST_SUCCESS = 'DRIVER_LIST_SUCCESS';
export const DRIVER_LIST_ERROR = 'DRIVER_LIST_ERROR';

export const DRIVER_REGISTER_REQUEST = 'DRIVER_REGISTER_REQUEST';
export const DRIVER_REGISTER_SUCCESS = 'DRIVER_REGISTER_SUCCESS';
export const DRIVER_REGISTER_ERROR = 'DRIVER_REGISTER_ERROR';
export const DRIVER_REGISTER_RESET = 'DRIVER_REGISTER_RESET';

export const DRIVER_DELETE_REQUEST = 'DRIVER_DELETE_REQUEST';
export const DRIVER_DELETE_ERROR = 'DRIVER_DELETE_ERROR';

export const DRIVER_EDIT_REQUEST = 'DRIVER_EDIT_REQUEST';
export const DRIVER_EDIT_ERROR = 'DRIVER_EDIT_ERROR';

export const DRIVER_UNDELETE_REQUEST = 'DRIVER_UNDELETE_REQUEST';
export const DRIVER_UNDELETE_ERROR = 'DRIVER_UNDELETE_ERROR';

export const DRIVER_TURN_RECIPIENT_REQUEST = 'DRIVER_TURN_RECIPIENT_REQUEST';
export const DRIVER_TURN_RECIPIENT_ERROR = 'DRIVER_TURN_RECIPIENT_ERROR';
export const DRIVER_TURN_RECIPIENT_SUCCESS = 'DRIVER_TURN_RECIPIENT_SUCCESS';
export const DRIVER_TURN_RECIPIENT_RESET = 'DRIVER_TURN_RECIPIENT_RESET';

export const DRIVER_PASSWORD_RECOVERY_REQUEST =
  'DRIVER_PASSWORD_RECOVERY_REQUEST';
export const DRIVER_PASSWORD_RECOVERY_ERROR = 'DRIVER_PASSWORD_RECOVERY_ERROR';
export const DRIVER_REGISTER_IMPORTED_REQUEST =
  'DRIVER_REGISTER_IMPORTED_REQUEST';

export const listDriverRequest = params => ({
  type: DRIVER_LIST_REQUEST,
  payload: { params },
});

export const listDriverSuccess = payload => ({
  type: DRIVER_LIST_SUCCESS,
  payload,
});

export const listDriverError = message => ({
  type: DRIVER_LIST_ERROR,
  payload: { message },
});

export const registerDriverRequest = (body, organizationId, params) => ({
  type: DRIVER_REGISTER_REQUEST,
  payload: { body, organizationId, params },
});

export const registerDriverSuccess = () => ({
  type: DRIVER_REGISTER_SUCCESS,
});

export const registerDriverError = message => ({
  type: DRIVER_REGISTER_ERROR,
  payload: { message },
});

export const deleteDriverRequest = (id, organizationId, params) => ({
  type: DRIVER_DELETE_REQUEST,
  payload: { id, organizationId, params },
});

export const deleteDriverError = message => ({
  type: DRIVER_DELETE_ERROR,
  payload: { message },
});

export const editDriverRequest = (id, body, organizationId, params) => ({
  type: DRIVER_EDIT_REQUEST,
  payload: { id, body, organizationId, params },
});

export const editDriverError = message => ({
  type: DRIVER_EDIT_ERROR,
  payload: { message },
});

export const undeleteDriverRequest = (id, params) => ({
  type: DRIVER_UNDELETE_REQUEST,
  payload: { id, params },
});

export const undeleteDriverError = message => ({
  type: DRIVER_UNDELETE_ERROR,
  payload: { message },
});

export const turnRecipientRequest = (id, params) => ({
  type: DRIVER_TURN_RECIPIENT_REQUEST,
  payload: { id, params },
});

export const turnRecipientError = message => ({
  type: DRIVER_TURN_RECIPIENT_ERROR,
  payload: { message },
});

export const turnRecipientSuccess = payload => ({
  type: DRIVER_TURN_RECIPIENT_SUCCESS,
  payload,
});

export const turnRecipientReset = () => ({
  type: DRIVER_TURN_RECIPIENT_RESET,
});

export const passwordRecoveryRequest = (id, body, params) => ({
  type: DRIVER_PASSWORD_RECOVERY_REQUEST,
  payload: { id, body, params },
});

export const passwordRecoveryError = message => ({
  type: DRIVER_PASSWORD_RECOVERY_ERROR,
  payload: { message },
});

export const importDriversRequest = (body, organizationId, params) => ({
  type: DRIVER_REGISTER_IMPORTED_REQUEST,
  payload: { body, organizationId, params },
});

export const resetDrivers = () => ({
  type: DRIVER_REGISTER_RESET,
});
