import produce from 'immer';

import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  AUTO_LOGOFF,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  FORGET_PASSWORD_RESET,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from '../actions';

const INIT_STATE = {
  user: null,
  token: '',
  signed: false,
  loading: false,
  error: '',
  autoLogoff: false,
  forgetPasswordLoading: null,
  forgetPasswordError: null,
  resetPasswordLoading: null,
  resetPasswordError: null,
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case LOGIN_USER: {
        draft.loading = true;
        draft.error = '';
        draft.autoLogoff = false;
        break;
      }
      case LOGIN_USER_SUCCESS: {
        draft.loading = false;
        draft.signed = true;
        draft.token = action.payload.token;
        draft.user = action.payload.user;
        break;
      }
      case LOGIN_USER_ERROR: {
        draft.loading = false;
        draft.signed = false;
        draft.error = action.payload.message;
        draft.token = '';
        draft.user = null;
        break;
      }
      case REGISTER_USER: {
        draft.loading = true;
        draft.error = '';
        draft.autoLogoff = false;
        break;
      }
      case REGISTER_USER_SUCCESS: {
        draft.loading = false;
        draft.error = '';
        break;
      }
      case REGISTER_USER_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }
      case LOGOUT_USER: {
        draft.loading = false;
        draft.signed = false;
        draft.token = '';
        draft.error = '';
        draft.user = null;
        break;
      }
      case AUTO_LOGOFF: {
        draft.autoLogoff = true;
        break;
      }

      case FORGET_PASSWORD_RESET: {
        draft.forgetPasswordLoading = null;
        draft.forgetPasswordError = null;
        break;
      }

      case FORGET_PASSWORD: {
        draft.forgetPasswordLoading = true;
        draft.forgetPasswordError = null;
        break;
      }

      case FORGET_PASSWORD_SUCCESS: {
        draft.forgetPasswordLoading = false;
        draft.forgetPasswordError = false;
        break;
      }

      case FORGET_PASSWORD_ERROR: {
        draft.resetPasswordLoading = false;
        draft.resetPasswordError = action.payload.error;
        break;
      }

      case RESET_PASSWORD: {
        draft.resetPasswordLoading = true;
        draft.resetPasswordError = null;
        break;
      }

      case RESET_PASSWORD_SUCCESS: {
        draft.resetPasswordLoading = false;
        draft.resetPasswordError = false;
        break;
      }

      case RESET_PASSWORD_ERROR: {
        draft.resetPasswordLoading = false;
        draft.resetPasswordError = action.payload.error;
        break;
      }

      default:
        return state;
    }
  });
};
