import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import * as stations from './actions';
import { getCurrentOrganization } from '../organizations/selectors';

function* list(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.list,
    APIParams({
      organizationId: currentOrganization.id,
      ...payload.params,
    })
  );

  if (response.ok) {
    const { gasStations } = response.data;

    yield put(stations.onListSuccess(gasStations));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(stations.onListError(message));
  }
}

function* toggle(api, { payload: { data } }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.blacklist,
    {
      organizationId: currentOrganization.id,
    },
    data
  );

  if (response.ok) {
    yield put(stations.onToggleSuccess(data));
  } else {
    const message = 'Não foi possível atualizar este posto';

    yield put(stations.onToggleError(message));
  }
}

export function* watchList(api) {
  yield takeEvery(stations.STATION_LIST_REQUEST, list, api);
}

export function* watchToggle(api) {
  yield takeEvery(stations.TOGGLE_STATION, toggle, api);
}

export default function* rootSaga(api) {
  yield all([fork(watchList, api), fork(watchToggle, api)]);
}
