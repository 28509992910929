import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const balanceDistribution = parameters =>
  api.get(
    `/admin/organization/balance/distribution${objectToURLParams(parameters)}`
  );

export const updateBalance = ({ data, organizationId }) =>
  api.put(
    `/admin/organization/updateBalance?organizationId=${organizationId}`,
    data
  );

export const updateVehicleBalance = ({ data, organizationId }) =>
  api.put(
    `/admin/organization/updateVehicleBalance?organizationId=${organizationId}`,
    data
  );

export const unshareBalance = ({ userId, organizationId }) =>
  api.put(
    `admin/organization/unshareBalance/${userId}?organizationId=${organizationId}`
  );

export const unshareVehicleBalance = ({ id, organizationId }) =>
  api.put(
    `admin/organization/unshareVehicleBalance/${id}?organizationId=${organizationId}`
  );

export const updateSharedBalance = ({ data, organizationId }) =>
  api.put(
    `/admin/organization/shareBalance?organizationId=${organizationId}`,
    data
  );

export const updateVehicleSharedBalance = ({ data, organizationId }) =>
  api.put(
    `admin/organization/shareVehicleBalance?organizationId=${organizationId}`,
    data
  );

export const reapBalance = ({ userId, organizationId }) =>
  api.put(
    `/admin/organization/reapBalance/${userId}?organizationId=${organizationId}`
  );

export const reapVehicleBalance = ({ id, organizationId }) =>
  api.put(
    `/admin/organization/reapVehicleBalance/${id}?organizationId=${organizationId}`
  );
