export const DISTRIBUTION = {
  REQUEST: 'balance/DISTRIBUTION_REQUEST',
  SUCCESS: 'balance/DISTRIBUTION_SUCCESS',
  ERROR: 'balance/DISTRIBUTION_ERROR',
};

export const UNSHARE = {
  REQUEST: 'balance/UNSHARE_BALANCE_REQUEST',
  SUCCESS: 'balance/UNSHARE_BALANCE_SUCCESS',
  ERROR: 'balance/UNSHARE_BALANCE_ERROR',
};

export const UPDATE = {
  REQUEST: 'balance/UPDATE_BALANCE_REQUEST',
  SUCCESS: 'balance/UPDATE_BALANCE_SUCCESS',
  ERROR: 'balance/UPDATE_BALANCE_ERROR',
};

export const COLLECT_CREDIT = {
  REQUEST: 'balance/COLLECT_CREDIT_REQUEST',
  SUCCESS: 'balance/COLLECT_CREDIT_SUCCESS',
  ERROR: 'balance/COLLECT_CREDIT_ERROR',
};

export const TOGGLE_SHARE = 'balance/TOGGLE_SHARE_BALANCE';

export const VEHICLE_DISTRIBUTION = {
  REQUEST: 'vehicleBalance/DISTRIBUTION_REQUEST',
  SUCCESS: 'vehicleBalance/DISTRIBUTION_SUCCESS',
  ERROR: 'vehicleBalance/DISTRIBUTION_ERROR',
};

export const VEHICLE_UNSHARE = {
  REQUEST: 'vehicleBalance/UNSHARE_BALANCE_REQUEST',
  SUCCESS: 'vehicleBalance/UNSHARE_BALANCE_SUCCESS',
  ERROR: 'vehicleBalance/UNSHARE_BALANCE_ERROR',
};

export const VEHICLE_UPDATE = {
  REQUEST: 'vehicleBalance/UPDATE_BALANCE_REQUEST',
  SUCCESS: 'vehicleBalance/UPDATE_BALANCE_SUCCESS',
  ERROR: 'vehicleBalance/UPDATE_BALANCE_ERROR',
};

export const VEHICLE_COLLECT_CREDIT = {
  REQUEST: 'vehicleBalance/COLLECT_CREDIT_REQUEST',
  SUCCESS: 'vehicleBalance/COLLECT_CREDIT_SUCCESS',
  ERROR: 'vehicleBalance/COLLECT_CREDIT_ERROR',
};

export const VEHICLE_TOGGLE_SHARE = 'vehicleBalance/TOGGLE_SHARE_BALANCE';

export const TOGGLE_ANYWHERE_REFUELING = 'balance/TOGGLE_ANYWHERE_REFUELING';

export const TOGGLE_ANYWHERE_VEHICLE_REFUELING =
  'vehicleBalance/TOGGLE_ANYWHERE_REFUELING';
