import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/organization/tank${objectToURLParams(parameters)}`);

export const listWallets = parameters =>
  api.get(`/admin/organization/tank/wallet${objectToURLParams(parameters)}`);

export const createWallet = payload =>
  api.post(`/admin/organization/tank/wallet`, payload);

export const updateWallet = (id, payload) =>
  api.put(`/admin/organization/tank/wallet/${id}`, payload);

export const updateMultipleWallets = payload =>
  api.put('/admin/organization/tank/wallets', payload);

export const updateWalletLimit = payload =>
  api.put(`/admin/organization/tank/wallet/limit`, payload);

export const shareBalance = payload =>
  api.put(`/admin/organization/tank/wallet/share-balance`, payload);

export const listUsers = parameters =>
  api.get(`/admin/organization/tank/users${objectToURLParams(parameters)}`);

export const refuel = (id, payload) =>
  api.post(`/admin/tank/${id}/refuel`, payload);
