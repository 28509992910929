export const USER_PERMISSION_REQUEST = 'USER_PERMISSION_REQUEST';
export const USER_PERMISSION_SUCCESS = 'USER_PERMISSION_SUCCESS';
export const USER_PERMISSION_ERROR = 'USER_PERMISSION_ERROR';

export const userPermissionRequest = userId => ({
  type: USER_PERMISSION_REQUEST,
  payload: { userId },
});

export const userPermissionSuccess = payload => ({
  type: USER_PERMISSION_SUCCESS,
  payload,
});

export const userPermissionError = message => ({
  type: USER_PERMISSION_ERROR,
  payload: { message },
});
