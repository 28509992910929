import produce from 'immer';
import * as actions from './actions';

const INIT_STATE = {
  data: null,
  loading: false,
  error: null,
  updating: false,
  updatingError: null,
  sharing: false,
  sharingError: null,
  collectingCredit: false,
  collectingCreditError: null,
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.EXPENSE_CENTER_VEHICLE.DISTRIBUTION.REQUEST:
        draft.loading = true;
        draft.error = null;
        break;

      case actions.EXPENSE_CENTER_VEHICLE.DISTRIBUTION.SUCCESS:
        draft.loading = false;
        draft.data = action.payload.data;
        draft.error = null;
        break;

      case actions.EXPENSE_CENTER_VEHICLE.DISTRIBUTION.ERROR:
        draft.loading = false;
        draft.data = null;
        draft.error = action.payload.error;
        break;

      case actions.EXPENSE_CENTER_VEHICLE.TOGGLE_SHARE:
        draft.sharing = true;
        draft.sharingError = null;
        break;

      case actions.EXPENSE_CENTER_VEHICLE.UPDATE.REQUEST:
        draft.updating = true;
        draft.updatingError = null;
        break;

      case actions.EXPENSE_CENTER_VEHICLE.UPDATE.SUCCESS:
        draft.updating = false;
        draft.updatingError = null;
        break;

      case actions.EXPENSE_CENTER_VEHICLE.UPDATE.ERROR:
        draft.updating = false;
        draft.updatingError = action.payload.error;
        break;

      case actions.EXPENSE_CENTER_VEHICLE.COLLECT_CREDIT.REQUEST:
        draft.collectingCredit = true;
        draft.collectingCreditError = null;
        break;

      case actions.EXPENSE_CENTER_VEHICLE.COLLECT_CREDIT.SUCCESS:
        draft.collectingCredit = false;
        draft.collectingCreditError = null;
        break;

      case actions.EXPENSE_CENTER_VEHICLE.COLLECT_CREDIT.ERROR:
        draft.collectingCredit = false;
        draft.collectingCreditError = action.payload.error;
        break;

      default:
        return state;
    }
  });
};
