import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import * as bills from './actions';
import { getCurrentOrganization } from '../organizations/selectors';

function* listResumes(api) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.list,
    APIParams({ organizationId: currentOrganization.id })
  );

  if (response.ok) {
    const { aggregateTransactions } = response.data;

    yield put(bills.onListResumesSuccess(aggregateTransactions));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(bills.onListResumesError(message));
  }
}

function* listPerPeriod(api, { payload }) {
  const response = yield call(
    api.listPerPeriod,
    APIParams({ ...payload.params })
  );

  if (response.ok) {
    if (response.data.aggregateTransactionsByPeriod.length) {
      const { aggregateTransactionsByPeriod } = response.data;

      yield put(bills.onListPerPeriodSuccess(aggregateTransactionsByPeriod));
    } else {
      yield put(bills.onListPerPeriodSuccess([]));
    }
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(bills.onListPerPeriodError(message));
  }
}

export function* watchListResumes(api) {
  yield takeEvery(bills.BILL_LIST_REQUEST, listResumes, api);
}

export function* watchListPerPeriod(api) {
  yield takeEvery(bills.BILLS_PER_PERIOD, listPerPeriod, api);
}

export default function* rootSaga(api) {
  yield all([fork(watchListResumes, api), fork(watchListPerPeriod, api)]);
}
