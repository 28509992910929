import produce from 'immer';

import { ACTIONS } from './actions';

const INIT_STATE = {
  users: [],
  currentPage: 1,
  totalPages: 0,
  totalItems: 0,
  skipItems: 0,
  selectedUser: {},
  permissions: [],
  loading: false,
  error: null,
  updateError: null,
  creationError: null,
};

export default (state = INIT_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ACTIONS.GET_USERS: {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case ACTIONS.GET_USERS_SUCCESS: {
        draft.loading = false;
        draft.users = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = null;
        break;
      }

      case ACTIONS.GET_USERS_ERROR: {
        draft.loading = false;
        draft.users = [];
        draft.error = action.payload.message;
        break;
      }

      case ACTIONS.GET_USER_PERMISSIONS: {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case ACTIONS.GET_USER_PERMISSIONS_SUCCESS: {
        draft.loading = false;
        draft.permissions = action.payload.permissions;
        draft.selectedUser = action.payload.user;
        draft.error = null;
        break;
      }

      case ACTIONS.GET_USER_PERMISSIONS_ERROR: {
        draft.loading = false;
        draft.permissions = [];
        draft.selectedUser = {};
        draft.error = action.payload.message;
        break;
      }

      case ACTIONS.UPDATE_USER_PERMISSION: {
        draft.updateError = null;
        break;
      }
      case ACTIONS.UPDATE_USER_PERMISSION_SUCCESS: {
        draft.updateError = null;
        draft.permissions = action.payload.permissions;
        break;
      }

      case ACTIONS.UPDATE_USER_PERMISSION_ERROR: {
        draft.updateError = action.payload.message;
        break;
      }

      case ACTIONS.CREATE_USER_PERMISSION: {
        draft.creationError = null;
        break;
      }
      case ACTIONS.CREATE_USER_PERMISSION_SUCCESS: {
        draft.creationError = null;
        draft.permissions = action.payload.permissions;
        break;
      }

      case ACTIONS.CREATE_USER_PERMISSION_ERROR: {
        draft.creationError = action.payload.message;
        break;
      }

      case ACTIONS.RESET_USER_PERMISSIONS_LIST: {
        draft.loading = false;
        draft.error = null;
        draft.permissions = [];
        draft.selectedUser = {};
        draft.creationError = null;
        draft.updateError = null;
        break;
      }

      default:
        return state;
    }
  });
