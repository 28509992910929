import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import { getCurrentOrganization } from '../organizations/selectors';

import {
  CONTAINER_LIST_REQUEST,
  CONTAINER_REGISTER_REQUEST,
  CONTAINER_DELETE_REQUEST,
  CONTAINER_EDIT_REQUEST,
  CONTAINER_UNDELETE_REQUEST,
  listContainerRequest,
  listContainerSuccess,
  listContainerError,
  registerContainerSuccess,
  registerContainerError,
  editContainerError,
  deleteContainerError,
  undeleteContainerError,
} from './actions';

function* listContainer(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const containers = yield call(
    api.listContainer,
    APIParams({ ...payload.params, organizationId: currentOrganization.id })
  );

  if (containers.ok) {
    const list = containers.data.docs;
    const { current: currentPage, total: totalPages } = containers.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = containers.data.items;

    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listContainerSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(listContainerError(message));
  }
}

function* registerContainer(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const apiRequest = yield call(
    api.registerContainer,
    payload.body,
    currentOrganization.id
  );

  if (apiRequest.ok) {
    yield put(registerContainerSuccess());
  } else {
    yield put(registerContainerError(apiRequest.data));
  }
  yield put(listContainerRequest(payload.params));
}

function* deleteContainer(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.deleteContainer,
    payload.id,
    currentOrganization.id
  );

  if (response.ok) {
    yield put(listContainerRequest(payload.params));
  } else {
    const message = 'Não foi possível remover o recipiente';
    yield put(deleteContainerError(message));
  }
}

function* editContainer(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.editContainer,
    payload.id,
    payload.body,
    currentOrganization.id
  );

  if (response.ok) {
    yield put(listContainerRequest(payload.params));
  } else {
    const message = 'Não foi possível editar o recipiente';
    yield put(editContainerError(message));
  }
}

function* undeleteContainer(api, { payload }) {
  const response = yield call(api.undeleteContainer, payload.id);

  if (response.ok) {
    yield put(listContainerRequest(payload.params));
  } else {
    const message = 'Não foi possível recuperar o recipiente';
    yield put(undeleteContainerError(message));
  }
}

export function* watchListContainerRequest(api) {
  yield takeEvery(CONTAINER_LIST_REQUEST, listContainer, api);
}

export function* watchRegisterContainerRequest(api) {
  yield takeEvery(CONTAINER_REGISTER_REQUEST, registerContainer, api);
}

export function* watchUDeleteContainerRequest(api) {
  yield takeEvery(CONTAINER_DELETE_REQUEST, deleteContainer, api);
}

export function* watchEditContainerRequest(api) {
  yield takeEvery(CONTAINER_EDIT_REQUEST, editContainer, api);
}

export function* watchUndeleteContainerRequest(api) {
  yield takeEvery(CONTAINER_UNDELETE_REQUEST, undeleteContainer, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListContainerRequest, api),
    fork(watchRegisterContainerRequest, api),
    fork(watchEditContainerRequest, api),
    fork(watchUDeleteContainerRequest, api),
    fork(watchUndeleteContainerRequest, api),
  ]);
}
