import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const balanceDistribution = parameters =>
  api.get(
    `/admin/organization/balance/distribution${objectToURLParams(parameters)}`
  );

export const updateSharedBalance = ({ data, organizationId }) =>
  api.put(
    `/v2/admin/organization/shareBalance?organizationId=${organizationId}`,
    data
  );

export const updateVehicleSharedBalance = ({ data, organizationId }) =>
  api.put(
    `/v2/admin/organization/shareVehicleBalance?organizationId=${organizationId}`,
    data
  );

export const reapBalance = ({ id, organizationId, data }) =>
  api.put(
    `/v2/admin/organization/reapBalance/${id}?organizationId=${organizationId}`,
    data
  );

export const reapVehicleBalance = ({ id, organizationId, data }) =>
  api.put(
    `/v2/admin/organization/reapVehicleBalance/${id}?organizationId=${organizationId}`,
    data
  );

export const unshareBalance = ({ id, organizationId, data }) =>
  api.put(
    `/v2/admin/organization/unshareBalance/${id}?organizationId=${organizationId}`,
    data
  );

export const unshareVehicleBalance = ({ id, organizationId, data }) =>
  api.put(
    `/v2/admin/organization/unshareVehicleBalance/${id}?organizationId=${organizationId}`,
    data
  );
