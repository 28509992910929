export const SUGGESTION_REQUEST = 'SUGGESTION_REQUEST';
export const SUGGESTION_SUCCESS = 'SUGGESTION_SUCCESS';
export const SUGGESTION_ERROR = 'SUGGESTION_ERROR';
export const PLACE_REQUEST = 'PLACE_REQUEST';
export const PLACE_SUCCESS = 'PLACE_SUCCESS';
export const PLACE_ERROR = 'PLACE_ERROR';

export const findPlaces = (google, AutocompletionRequest) => ({
  type: SUGGESTION_REQUEST,
  payload: { google, request: AutocompletionRequest },
});

export const onFindPlacesSuccess = data => ({
  type: SUGGESTION_SUCCESS,
  payload: { data },
});

export const onFindPlacesError = message => ({
  type: SUGGESTION_ERROR,
  payload: { message },
});

export const getPlace = (google, PlaceDetailsRequest) => ({
  type: PLACE_REQUEST,
  payload: { google, request: PlaceDetailsRequest },
});

export const onGetPlaceSuccess = data => ({
  type: PLACE_SUCCESS,
  payload: { data },
});

export const onGetPlaceError = message => ({
  type: PLACE_ERROR,
  payload: { message },
});
