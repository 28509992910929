import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import { isNil } from 'lodash';
import { getCurrentOrganization } from '../../organizations/selectors';
import * as selectors from '../selectors';
import * as actions from './actions';

function* handleDistributionRequest(api, { payload }) {
  try {
    const currentOrganization = yield select(getCurrentOrganization);
    const response = yield call(
      api.balanceDistribution,
      APIParams({ ...payload.params, organizationId: currentOrganization.id })
    );

    if (!response) {
      throw new Error('request error');
    }

    const data = {
      balance: response.data.balance,
      users: response.data.users.map(user => ({
        name: `${user.firstName} ${user.lastName}`,
        sharedBalance: user.wallets.some(wallet => wallet.sharedBalance)
          ? 1
          : 0,
        balance: user.wallets.reduce(
          (total, wallet) => total + wallet.balance,
          0
        ),
        id: user._user,
        role: user.role,
        userOrg: user._id,
        wallets: user.wallets,
        allowAnywhereRefueling: user.wallets.every(
          wallet => wallet.allowAnywhereRefueling
        )
          ? 1
          : 0,
      })),
      hasAnywhere: response.data.hasAnywhere,
    };

    yield put(actions.expenseCenterUserBalanceSuccess(data));
  } catch (error) {
    yield put(actions.expenseCenterUserBalanceError(error.message));
  }
}

function* handleShareBalanceToggle(api, { payload }) {
  try {
    const currentOrganization = yield select(getCurrentOrganization);
    const { users, balance, hasAnywhere } = yield select(
      selectors.getExpenseCenterUserBalance
    );

    const response = yield call(api.unshareBalance, {
      organizationId: currentOrganization.id,
      id: payload.id,
      data: {
        sharedBalance: payload.status,
        ...(payload.wallet && { wallet: payload.wallet }),
        ...(payload.expenseCenter && {
          expenseCenter: payload.expenseCenter,
        }),
        ...(!isNil(payload.allowAnywhereRefueling) && {
          allowAnywhereRefueling: payload.allowAnywhereRefueling,
        }),
      },
    });

    if (!response) {
      throw new Error('request error');
    }

    let newBalanceValue = balance;

    const updatedUsers = users.map(user => {
      if (user.id === payload.id) {
        newBalanceValue += user.balance;

        const wallets = user.wallets.map(wallet => {
          if (wallet._id === payload.wallet) {
            return {
              ...wallet,
              balance: 0,
              sharedBalance: payload.status,
            };
          }

          return {
            ...wallet,
            balance: 0,
            ...(wallet.sharedBalance &&
              payload.status && { sharedBalance: false }),
          };
        });

        if (!payload.wallet) wallets.push(response.data);

        return {
          ...user,
          sharedBalance: +payload.status,
          balance: 0,
          wallets,
        };
      }
      return user;
    });

    const data = {
      balance: newBalanceValue,
      users: updatedUsers,
      hasAnywhere,
    };

    yield put(actions.expenseCenterUserBalanceSuccess(data));
  } catch (error) {
    yield put(actions.expenseCenterUserBalanceError(error.message));
  }
}

function* handleUpdateBalanceRequest(api, { payload }) {
  try {
    const currentOrganization = yield select(getCurrentOrganization);
    const { users, balance, hasAnywhere } = yield select(
      selectors.getExpenseCenterUserBalance
    );

    const totalToAdd = payload.data.reduce(
      (accumulator, currentValue) => accumulator.share + currentValue.share,
      { share: 0 }
    );

    if (totalToAdd > balance) {
      throw new Error('O saldo total ultrapassa o limite disponível');
    }

    const request = yield call(api.updateSharedBalance, {
      data: payload.data,
      organizationId: currentOrganization.id,
    });

    if (!request.ok) {
      throw new Error('update shared balance');
    }

    yield put(
      actions.expenseCenterUserBalanceSuccess({
        balance: balance - totalToAdd,
        users,
        hasAnywhere,
      })
    );

    yield put(actions.expenseCenterUpdateUserBalanceSuccess());
  } catch (error) {
    yield put(actions.expenseCenterUpdateUserBalanceError(error.message));
  }
}

function* handleCollectCreditRequest(api, { payload }) {
  try {
    const currentOrganization = yield select(getCurrentOrganization);
    const { users, balance, hasAnywhere } = yield select(
      selectors.getExpenseCenterUserBalance
    );

    let collectedBalance = 0;

    const dataWithUpdates = users.map(d => {
      if (d.id !== payload.id) {
        return d;
      }

      const wallets = d.wallets.map(wallet => {
        if (payload.wallets.includes(wallet._id)) {
          collectedBalance += wallet.balance;
          return { ...wallet, balance: 0 };
        }
        return wallet;
      });

      return {
        ...d,
        wallets,
        balance: Math.abs(d.balance - collectedBalance),
      };
    });

    const request = yield call(api.reapBalance, {
      id: payload.id,
      organizationId: currentOrganization.id,
      data: payload.wallets,
    });

    if (!request.ok) {
      throw new Error('update shared balance');
    }

    yield put(
      actions.expenseCenterUserBalanceSuccess({
        balance: balance + collectedBalance,
        users: dataWithUpdates,
        hasAnywhere,
      })
    );

    yield put(actions.expenseCenterCollectUserCreditSuccess());
  } catch (error) {
    yield put(actions.expenseCenterCollectUserCreditError(error.message));
  }
}

function* handleAllowAnywhereRefueling(api, { payload }) {
  try {
    const currentOrganization = yield select(getCurrentOrganization);

    const response = yield call(api.allowAnywhereRefueling, {
      organizationId: currentOrganization.id,
      userId: payload.userId,
      data: payload.data,
    });

    if (!response) {
      throw new Error('request error');
    }

    if (response.data?.error) {
      throw new Error(response.data?.message);
    }

    const { users, balance, hasAnywhere } = yield select(
      selectors.getExpenseCenterUserBalance
    );

    const updatedUsers = users.map(user => {
      if (user.id === payload.userId) {
        return {
          ...user,
          allowAnywhereRefueling: +payload.data.allowAnywhereRefueling,
        };
      }

      return user;
    });

    const data = {
      balance,
      users: updatedUsers,
      hasAnywhere,
    };

    yield put(actions.expenseCenterUserBalanceSuccess(data));
  } catch (error) {
    yield put(actions.expenseCenterUserBalanceError(error.message));
  }
}

function* watchDistributionRequest(api) {
  yield takeEvery(
    actions.EXPENSE_CENTER_USER.DISTRIBUTION.REQUEST,
    handleDistributionRequest,
    api
  );
}

function* watchShareBalanceFromUser(api) {
  yield takeEvery(
    actions.EXPENSE_CENTER_USER.TOGGLE_SHARE,
    handleShareBalanceToggle,
    api
  );
}

function* watchUpdateBalanceRequest(api) {
  yield takeEvery(
    actions.EXPENSE_CENTER_USER.UPDATE.REQUEST,
    handleUpdateBalanceRequest,
    api
  );
}

function* watchCollectCreditRequest(api) {
  yield takeEvery(
    actions.EXPENSE_CENTER_USER.COLLECT_CREDIT.REQUEST,
    handleCollectCreditRequest,
    api
  );
}

function* watchAllowAnywhereRefueling(api) {
  yield takeEvery(
    actions.EXPENSE_CENTER_USER.TOGGLE_ANYWHERE_REFUELING,
    handleAllowAnywhereRefueling,
    api
  );
}

export default function* rootSaga(api) {
  yield all([
    fork(watchDistributionRequest, api),
    fork(watchShareBalanceFromUser, api),
    fork(watchUpdateBalanceRequest, api),
    fork(watchCollectCreditRequest, api),
    fork(watchAllowAnywhereRefueling, api),
  ]);
}
