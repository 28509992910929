import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/organization/gasStation${objectToURLParams(parameters)}`);

export const blacklist = (parameters, payload) =>
  api.post(
    `/admin/organization/blacklist${objectToURLParams(parameters)}`,
    payload
  );
