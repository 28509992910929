export const ROLES = {
  ADMIN_ORG: '5a282dffc0844f13cb8a7be9',
  USER: '5a282dffc0844f13cb8a7be7',
  USER_ORG: '5a282dffc0844f13cb8a7be8',
  SUPER_ADMIN_ORG: '5b7ed2fd4adf6bb06181f1d6',
  ADMIN_INTERNAL_TANK: '658c36e580de86eeef4a8465',
  USER_INTERNAL_TANK: '658c36e580de86eeef4a8466',
};

export const ADMIN_ROLE_OPTIONS = [
  { label: 'AdminOrg', value: ROLES.ADMIN_ORG, key: 1 },
  { label: 'SuperAdminOrg', value: ROLES.SUPER_ADMIN_ORG, key: 2 },
];

export const INTERNAL_TANK_ROLES_OPTIONS = [
  { label: 'UserInternalTank', value: ROLES.USER_INTERNAL_TANK, key: 0 },
  { label: 'AdminInternalTank', value: ROLES.ADMIN_INTERNAL_TANK, key: 1 },
];

export const CATEGORY_NAME = {
  [ROLES.USER]: 'contractor',
  [ROLES.USER_ORG]: 'employee',
  [ROLES.ADMIN_ORG]: 'administrator',
  [ROLES.SUPER_ADMIN_ORG]: 'administrator',
};

export const EXPENSE_CENTER_PLAN = '6452c0f7d9b1b9a85f54c461';

export const PERMISSIONS = {
  ADMIN: {
    LIST: 'com.litroz.portal.admin.list',
    ADD: 'com.litroz.portal.admin.add',
    REMOVE: 'com.litroz.portal.admin.remove',
  },
  BRANCHES: {
    LIST: 'com.litroz.portal.branches.list',
    ADD: 'com.litroz.portal.branches.add',
    REMOVE: 'com.litroz.portal.branches.remove',
  },
  USER_PERMISSIONS: {
    LIST: 'com.litroz.portal.permission.list',
    ADD: '',
  },
  BALANCE_DISTRIBUTION: {
    LIST: 'com.litroz.portal.balanceDistribution.list',
    ADD: 'com.litroz.portal.balanceDistribution.add',
  },
  SHARED_BALACE: {
    ADD: 'com.litroz.portal.sharedBalance.add',
  },
  BALANCE_SHARING: {
    LIST: 'com.litroz.portal.balanceSharing.list',
  },
  ANYWHERE_PERMISSIONS: {
    ADD: 'com.litroz.portal.anywherePermission.add',
  },
  BILLS: {
    LIST: 'com.litroz.portal.bill.list',
  },
  CONTAINER: {
    LIST: 'com.litroz.portal.container.list',
    ADD: 'com.litroz.portal.container.add',
    REMOVE: 'com.litroz.portal.container.remove',
  },
  EXPENSE_CENTER: {
    ADD: 'com.litroz.portal.expenseCenter.add',
    LIST: 'com.litroz.portal.expenseCenter.list',
    REMOVE: 'com.litroz.portal.expenseCenter.remove',
  },
  DEPOSITS: {
    LIST: 'com.litroz.portal.deposit.list',
    ATTACHMENTS: {
      LIST: 'com.litroz.portal.depositAttachments.list',
    },
  },
  DRIVER: {
    LIST: 'com.litroz.portal.driver.list',
    ADD: 'com.litroz.portal.driver.add',
    REMOVE: 'com.litroz.portal.driver.remove',
  },
  GAS_STATIONS: {
    LIST: 'com.litroz.portal.gasStation.list',
  },
  RECIPIENT: {
    LIST: 'com.litroz.portal.recipient.list',
    ADD: 'com.litroz.portal.recipient.add',
    REMOVE: 'com.litroz.portal.recipient.remove',
  },
  SETTINGS: {
    LIST: 'com.litroz.portal.settings.list',
    ADD: 'com.litroz.portal.settings.add',
  },
  TRANSACTIONS: {
    LIST: 'com.litroz.portal.transaction.list',
  },
  VEHICLE: {
    LIST: 'com.litroz.portal.vehicle.list',
    ADD: 'com.litroz.portal.vehicle.add',
    REMOVE: 'com.litroz.portal.vehicle.remove',
  },
  CREDIT_TRANSFER: {
    ADD: 'com.litroz.portal.creditTransfer.add',
  },
  PURCHASE: {
    ADD: 'com.litroz.portal.purchase.add',
  },
  INTERNAL_TANK: {
    BALANCE: {
      LIST: 'com.litroz.portal.internalTankBalance.list',
      ADD: 'com.litroz.portal.internalTankBalance.add',
    },
    TANKS: {
      LIST: 'com.litroz.portal.internalTank.list',
    },
    USERS: {
      LIST: 'com.litroz.portal.internalTankUser.list',
    },
    TRANSACTIONS: {
      LIST: 'com.litroz.portal.internalTankTransaction.list',
    },
  },
  ROUTE_SCRIPT: {
    LIST: 'com.litroz.portal.routeScript.list',
  },
};

export const PATH = {
  ADMIN: '/app/admins',
  PURCHASE: '/app/balance/purchase',
  BRANCHES: '/app/branches',
  ENTRIES: {
    CONTAINER: '/app/entries/container',
    DRIVER: '/app/entries/driver',
    RECIPIENT: '/app/entries/recipient',
    VEHICLE: '/app/entries/vehicle',
  },
  BALANCE: {
    DISTRIBUTION: '/app/balance/distribution',
  },
  SECURITY: {
    USERS: '/app/security/users',
  },
  SYSTEM: {
    SETTINGS: '/app/settings',
  },
  TRANSACTIONS: '/app/transactions',
  DEPOSITS: '/app/deposits',
  BILLS: '/app/bills',
  INTERNAL_TANK: {
    TANKS: '/app/fuel-management/tanks',
    BALANCE: '/app/fuel-management/balance',
  },
  REPORTS: {
    BALANCE_SHARING: '/app/reports/balance-sharing',
  },
  STATIONS: '/app/stations',
};
