import produce from 'immer';

import {
  ADMINISTRATORS_LIST_REQUEST,
  ADMINISTRATORS_LIST_SUCCESS,
  ADMINISTRATORS_LIST_ERROR,
  REGISTER_ADMINISTRATOR,
  REGISTER_ADMINISTRATOR_SUCCESS,
  REGISTER_ADMINISTRATOR_ERROR,
  DELETE_ADMINISTRATOR,
  DELETE_ADMINISTRATOR_ERROR,
  EDIT_ADMINISTRATOR,
  EDIT_ADMINISTRATOR_ERROR,
  EDIT_ADMINISTRATOR_BRANCH,
  EDIT_ADMINISTRATOR_BRANCH_ERROR,
} from './actions';

const INIT_STATE = {
  list: [],
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
  success: false,
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ADMINISTRATORS_LIST_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case ADMINISTRATORS_LIST_SUCCESS: {
        draft.loading = false;
        draft.list = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      }

      case ADMINISTRATORS_LIST_ERROR: {
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      }

      case REGISTER_ADMINISTRATOR: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case REGISTER_ADMINISTRATOR_SUCCESS: {
        draft.loading = false;
        draft.error = '';
        break;
      }

      case REGISTER_ADMINISTRATOR_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }

      case DELETE_ADMINISTRATOR: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case DELETE_ADMINISTRATOR_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }

      case EDIT_ADMINISTRATOR: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case EDIT_ADMINISTRATOR_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }

      case EDIT_ADMINISTRATOR_BRANCH: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case EDIT_ADMINISTRATOR_BRANCH_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }

      default:
        return state;
    }
  });
};
